import React from "react";
import { Link } from "react-router-dom";
import {
  FaAngleDown,
  FaAngleUp,
  FaFacebookF,
  FaGoogle,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaXTwitter,
} from "react-icons/fa6";
import { MdCall, MdEmail } from "react-icons/md";
import { useState } from "react";
import Dropdown from "./Dropdown";
import { useSpring, animated } from "react-spring";
import { useLocation } from "react-router-dom";
const Navbar = () => {
  const [showDrop, SetshowDrop] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    SetshowDrop(false);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const location = useLocation();
  // Define the animation properties using useSpring
  const fadeIn = useSpring({
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 50, friction: 8 },
  });

  return (
    <div className="h-fit w-full sticky font-jost ">
      <div className="bg-[#093E86] flex justify-center p-3 max-md:flex-col max-md:items-center ">
        <div className="w-[90%] flex justify-between">
          <div className="text-white md:flex gap-2 items-center hidden">
            <div className="bg-white rounded-full p-[3px]">
              <MdCall className="text-[#093E86] text-[10px]" />
            </div>
            <span className=" para text-white ">+91 8799451956</span>
          </div>

          <div className="md:hidden  flex max-sm:hidden  items-center gap-1">
            <div className="bg-white rounded-full p-1">
              <FaLinkedinIn className="text-[#093E86] text-[10px]" />
            </div>
          </div>

          <div className="text-white flex max-md:flex-col  justify-center  items-center max-md:items-start gap-2 max-md:gap-1">
            <div className="flex items-center gap-2">
              <div className="bg-white rounded-full p-[3px] ">
                <MdEmail className="text-[#093E86] text-[10px]" />
              </div>

              <span className=" pr-2  para text-white max-sm:text-sm ">
                investment@shubhalakshmi.com
              </span>
            </div>

            {/* <div className="md:flex hidden gap-1">
              <div className="bg-white rounded-full p-1">
                <FaLinkedinIn className="text-[#093E86] text-[10px]" />
              </div>
            </div> */}

            <div className="text-white md:hidden gap-2 items-center flex">
              <div className="bg-white rounded-full p-[3px]">
                <MdCall className="text-[#093E86] text-[10px]" />
              </div>
              <span className=" para text-white ">+91 8799451956</span>
            </div>
          </div>
        </div>
      </div>

      <animated.nav
        style={fadeIn}
        className=" text-white w-full  absolute  flex justify-center  "
      >
        <div className="relative flex w-[86%] ">
          <div className="flex justify-center items-center bg-white p-4 h-[160px] max-lg:h-[120px]  max-sm:h-[95px]  w-[170px] max-lg:w-[127px]  max-sm:w-[100px] border-2 rounded-bl-[50px] max-sm:rounded-bl-[30px] border-[#093E86] max-sm:ml-1">
            <img
              className=""
              src="/images/logos/shubhalakshmi-logo.png"
              alt=""
            />
          </div>

          <ul className="flex justify-center items-center bg-gradient-to-b from-[#0079BD] to-[#093E86]  w-full h-[105px] max-lg:h-[80px]  max-md:hidden">
            <div className="space-x-16 max-xl:space-x-11 max-lg:space-x-6 text-[20px] max-lg:text-[14px]  text-white font-medium leading-[145%] ">
              <Link
                to="/home"
                className="text-shadow pb-4 hover:border-b-2 border-[#007FC2]  "
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-shadow pb-4 hover:border-b-2 border-[#007FC2]  "
              >
                About Us
              </Link>

              <div className="inline-flex rounded-md">
                <div
                  className="relative group"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="flex items-center justify-center">
                    <Link href="#" className="px-4 py-2  text-white   ">
                      Our Business
                    </Link>
                    <div>{isHovered ? <FaAngleDown /> : <FaAngleUp />}</div>
                  </div>

                  <div className="bg-white border rounded-sm transform scale-0 group-hover:scale-125 absolute transition duration-300 ease-in-out origin-top w-full">
                    <ul className="p-2">
                      <Link
                        to="/Shubhalakshmi_polyesters"
                        className="block px-4 py-2 text-sm max-lg:text-xs text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                        onClick={handleClick}
                      >
                        Shubhalakshmi Polyesters Ltd
                      </Link>
                      <Link
                        to="/sunkon"
                        className="block px-4 py-2 text-sm max-lg:text-xs text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                        onClick={handleClick}
                      >
                        Sunkon Energy Pvt Ltd
                      </Link>
                      {/* <Link
                        to="/investment"
                        className="block px-4 py-2 text-sm max-lg:text-xs text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                        onClick={handleClick}
                      >
                        Investments
                      </Link> */}
                    </ul>
                  </div>
                  {/* )} */}
                </div>
              </div>

              <Link
                to="/contact"
                className="text-shadow pb-4 hover:border-b-2 border-[#007FC2]  "
              >
                Contact Us
              </Link>
            </div>
          </ul>

          <div className="hidden max-md:block w-full">
            <nav className=" bg-gradient-to-b from-[#0079BD] to-[#093E86]  p-4  h-[63px] w-full  sticky ">
              <div className="container mx-auto flex items-center justify-end">
                <Dropdown />
              </div>
            </nav>
          </div>
        </div>
      </animated.nav>
    </div>
  );
};

export default Navbar;
