import React from "react";
import { GoDotFill } from "react-icons/go";

const Whyus = ({ topic, heading, para, btn, img }) => {
  return (
    <div className="flex  justify-center">
      <div className="flex max-lg:flex-col items-center justify-center gap-16  w-[90%] max-md:w-full px-3 ">
        <div className="">
          <img className=" rounded-[20px]" src={img} alt="#img" />
        </div>

        <div className="text-left w-full  space-y-5  max-xl:space-y-3 max-sm:space-y-2  ">
          {topic ? (
            <ul className="flex items-center gap-4 max-sm:gap-2 list-none">
              {/* <GoDotFill className="text-2xl max-xl:text-xl max-md:text-lg" />{" "} */}
              <li className=" para text-black font-jost font-semibold text-2xl hover:border-b-2 border-[#093E86]">
                {topic}
              </li>
            </ul>
          ) : (
            ""
          )}
          {heading ? (
            <p className="text-[40px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] ">
              {heading}
            </p>
          ) : (
            ""
          )}
          <p className="w-full  font-jost font-normal text-justify text-[#383838]  para_Home ">
            {para}
          </p>
          {btn ? (
            <button className="border-[1px] border-[#093E86] p-2 max-sm:p-1 rounded">
              {btn}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Whyus;
