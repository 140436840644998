import React from "react";

const ShubhalakshmiLogo = () => {
  return (
    <div className="bg-white w-fit  rounded-full p-3">
      <img src="/images/logos/shubhalakshmi-logo.png" alt="#img"  className="h-10 w-10" />
    </div>
  );
};

export default ShubhalakshmiLogo;
