import React from "react";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = ({ children }) => {
  return (
    <div className="text-black text-2xl">
      <nav>
        <Navbar />{" "}
      </nav>
      <section>
         <ToastContainer />
        {children}
        </section>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Main;
