import React, { useEffect, useState } from "react";
import BlogTemplate from "./BlogTemplate";
import { BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import axios from "axios";
import { SetID } from "../../redux/BlogSlice";
import { useDispatch } from "react-redux";
import { TESelect } from "tw-elements-react";

const BlogContainer = () => {
  const data = [
    {
      id: "1",
    },
    {
      id: "2",
    },
    {
      id: "3",
    },
    {
      id: "4",
    },
    {
      id: "5",
    },
  ];
  const dispatch = useDispatch();
  const [blogData, setblogData] = useState([]);
  const apiurl = process.env.REACT_APP_API;
  console.log(apiurl);
  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/blog/getBlogData`
      );

      console.log(data);
      setblogData(data.blogdata);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const Categories = [
    { text: "Categories", value: 1 },
    { text: "Two", value: 2 },
    { text: "Three", value: 3 },
    { text: "Four", value: 4 },
    { text: "Five", value: 5 },
    { text: "Six", value: 6 },
  ];

  const Instagram = [
    { text: "Instagram", value: 1 },
    { text: "Corporate", value: 2 },
    { text: "Business", value: 3 },
    { text: "Entertainment", value: 4 },
    { text: "Innovations", value: 5 },
  ];

  const [selectedCategories, setSelectedCategories] = useState(1);
  const [selectedInstagram, setSelectedInstagram] = useState(1);

  return (
    <div className="flex justify-center ">
      <div className=" flex  max-md:flex-col-reverse w-[90%]  justify-center gap-10 ">
        <div className="flex flex-col justify-start gap-10">
          {blogData.map((blog) => {
            return <BlogTemplate blog={blog} getData={getData} />;
          })}
        </div>

        <div className="flex flex-col ">
          <div
            className="w-[351px] max-lg:w-[270px] max-md:w-full  bg-[#093E86] flex flex-col max-md:flex-row justify-center items-center gap-3 max-sm:gap-1
         p-10 max-lg:p-5 max-md:p-1 max-md:my-0"
          >
            <h1 className="text-white text-[20px] font-bold leading-[145%] text-left p-2 max-sm:p-1">
              Search
            </h1>

            <div className="bg-[#FFF] flex w-full justify-center p-5 max-md:p-2 my-3 max-md:my-0">
              <input
                type="text"
                placeholder="SEARCH KEYWORD"
                className="placeholder:text-[12px] placeholder:align-middle placeholder:content-center outline-none  placeholder:font-bold w-full  placeholder:tracking-[2.4px] placeholder:text-[#000]"
              />
              <div className="flex items-center">
                <BiSearchAlt className="text-[#093E86] " />
              </div>
            </div>
          </div>
          <div className="max-md:flex justify-center gap-10 hidden">
            <div className="flex justify-center">
              <div className="relative mb-3 md:w-96 pt-5">
                <select
                  name="Categories"
                  onChange={(e) => {
                    const newvalue = e.target.value;
                    setSelectedCategories(newvalue);
                  }}
                  className="transition-all ease-in-out duration-300 text-sm outline-none border border-gray-300 p-2 rounded-md shadow-lg"
                >
                  {Categories.map((item) => {
                    return (
                      <option value={`${item.value}`} className="text-sm">
                        {item.text}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="relative mb-3 md:w-96 pt-5">
                <select
                  name="Instagram"
                  onChange={(e) => {
                    const newvalue = e.target.value;
                    setSelectedInstagram(newvalue);
                  }}
                  className="transition-all ease-in-out duration-300 text-sm outline-none border border-gray-300 p-2 rounded-md shadow-lg"
                >
                  {Instagram.map((item) => {
                    return (
                      <option value={`${item.value}`} className="text-sm">
                        {item.text}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div
            className="w-[351px] max-lg:w-[270px]  max-md:w-full  bg-white flex flex-col max-md:hidden justify-center items-start gap-3 max-sm:gap-1
         p-10 max-lg:p-5 max-md:p-1 max-md:my-0"
          >
            <h1 className="text-black text-[25px] font-semibold leading-[145%] text-left p-2 max-sm:p-1">
              Categories
            </h1>

            <div className="bg-[#F9F9F9] flex items-center w-full justify-center p-5 max-md:p-2 my-3 max-md:my-0">
              <input
                type="text"
                placeholder="SEARCH KEYWORD"
                className="placeholder:text-[12px] outline-none  p-1 placeholder:font-bold bg-[#F9F9F9] w-full placeholder:leading-[145%] placeholder:tracking-[2.4px] placeholder:text-[#000]"
              />
              <BiSearchAlt className="text-[#093E86] " />
            </div>
          </div>

          <div className="space-y-3 recent block max-md:hidden ">
            <h1 className="text-black text-[25px] font-semibold  tracking-[1.25px] text-left">
              Recent Posts
            </h1>
            <div className="w-[351px] max-lg:w-[270px]">
              <img
                src="/Images/recentPost.png"
                alt="Recent Post Image Description"
                className=""
              />
              <div className="bg-[#F9F9F9] p-3">
                <h1 className="text-black text-left text-[15px] max-sm:text-[10px]  font-medium leading-normal tracking-[0.75px]">
                  Tracking metrics are essential to evaluate the efficacy of any
                  project or campaign.
                </h1>
                <p className="text-[#093E86] text-left text-[12px] font-bold leading-[145%] tracking-[0.75px]">
                  16.01.23
                </p>
              </div>
            </div>
          </div>

          <div className="mt-10  max-md:hidden ">
            <div className="">
              <h1 className="text-black text-[25px] font-medium leading-[145%] tracking-[1.25px] text-left">
                Instagram
              </h1>

              <ul className="flex gap-2 para text-black">
                <li className=" rounded-[50px] bg-[#F9F9F9] p-2 tracking-[0.75px]">
                  Corporate
                </li>
                <li className=" rounded-[50px] bg-[#F9F9F9] p-2 tracking-[0.75px]">
                  Business
                </li>
              </ul>

              <ul className="flex gap-2 mt-2 para text-black">
                <li className=" rounded-[50px] bg-[#F9F9F9] p-2 leading-6 tracking-[0.75px]">
                  Entertainment
                </li>
                <li className=" rounded-[50px] bg-[#F9F9F9] p-2 tracking-[0.75px]">
                  Innovations
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogContainer;
