import React from "react";
import Mapcontact from "../Components/Mapcontact";
import Banner from "../Components/Banner";
import ContactForm from "../Components/Contact/ContactForm";
import { GoDotFill } from "react-icons/go";
import { useLocation } from "react-router-dom/dist";
import { useEffect } from "react";
import Main from "../Main";
const Contact = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Main>
      <div className="space-y-32  max-xl:space-y-24 max-lg:space-y-20 max-sm:space-y-10">
        <Banner heading={"Contact Us"} />
        <Mapcontact />
       

        <ContactForm />
      </div>
    </Main>
  );
};

export default Contact;
