import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import About from "./Pages/About";
import Company from "./Pages/Shubhalakshmipolyesters";
import Home from "./Pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sunkon from "./Pages/Sunkon";
import Shubhalakshmipolyesters from "./Pages/Shubhalakshmipolyesters";
import Blog from "./Pages/Blog";
import Investments from "./Pages/Investments";
import Contact from "./Pages/Contact";
import BlogDetailPage from "./Components/Blog/BlogDetailPage";
import Career from "./Pages/Career";
import CareerDetailPage from "./Components/Contact/CareerDetailPage";
import PrivacyPage from "./Pages/PrivacyPage";
import FAQ from "./Pages/FAQ";
import AdminLogin from "./Admin/AdminLogin";
import AdminDashboard from "./Admin/AdminDashboard";
import AdminMain from "./Admin/AdminMain";
import { AdminCareer } from "./Admin/AdminCareer";
import AdminCareerApplication from "./Admin/AdminCareerApplication";
import AdminBlog from "./Admin/AdminBlog";
import PrivateRoute from "./Route/ProtectRoute";
import AdminInquery from "./Admin/AdminInquery";
import { useEffect } from "react";
import NotFound from "./Pages/NotFound";
import Futureprospects from "./Pages/Futureprospects";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route
          path="/admin/admin-shubhalakshmi-login"
          element={<AdminLogin />}
        />

        <Route path="/admin" element={<PrivateRoute />}>
          {/* <Route path="/admin" element={<AdminLogin />} /> */}
          <Route path="" element={<AdminDashboard />} />
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="/admin/jobpost" element={<AdminCareer />} />
          <Route path="/admin/adminblog" element={<AdminBlog />} />
          <Route path="/admin/adminInquery" element={<AdminInquery />} />
          <Route
            path="/admin/jobapplication"
            element={<AdminCareerApplication />}
          />
        </Route>

        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/Shubhalakshmi_polyesters"
            element={<Shubhalakshmipolyesters />}
          />
          <Route path="/sunkon" element={<Sunkon />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/investment" element={<Investments />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogdetail" element={<BlogDetailPage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/careerProfile" element={<CareerDetailPage />} />
          <Route path="/futureprospects" element={<Futureprospects />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
