import React from "react";

const Banner = ({ heading }) => {
  return (
    <div
      className="bg-cover bg-top  bg-no-repeat h-[600px]  shadow-xl  max-xl:h-[500px] max-sm:h-[400px] "
      style={{ backgroundImage: "url('/images/about-page-banner.jpg')" }}
    >
      <div className=" text-white h-full w-full bg-opacity-40 bg-black  max-xl:w-[full] flex justify-center items-center pl-20 max-small-sm:pl-2">
        <div className="text-left max-lg:text-[50px] max-sm:text-[35px] w-[80%] max-small-sm:w-[90%] ">
          <p className="text-[40px] max-xl:text-[40px] font-jost max-sm:text-[25px]  leading-[100%] font-[500] ">
            {heading}
          </p>
          <p className="text-[22px]   font-jost max-sm:text-[12px]  leading-normal font-[500]  ">
            Home &#62; {heading}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
