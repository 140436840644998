import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Homebanner from "../Components/Homebanner";
import Whyus from "../Components/Whyus";
import Rightimageconten from "../Components/Rightimageconten";
import Patner from "../Components/Patner";
import Founderwords from "../Components/Founderwords";
import Mapcontact from "../Components/Mapcontact";
import Main from "../Main";
import { SmoothScroll } from "../Components/Animation/SmothScroll";

const Home = () => {
  return (
    <Main>
      <div className="space-y-32 max-xl:space-y-24 max-lg:space-y-20 max-sm:space-y-10">
        <Homebanner />
        <SmoothScroll>
        <Whyus
          img={"/images/why-banner-1.jpg"}
          topic={"About Us"}
          para={
            "We believe financial legacies built on diversified, sustainable assets stand the test of time. We are a family office dedicated to managing our family's wealth across a spectrum of traditional and alternative investments, from fixed income and private equity to venture capital and direct equity. Our core philosophy rests on disciplined diversification and a relentless pursuit of risk-adjusted returns. We leverage deep industry expertise, a global perspective, and an unwavering commitment to sustainability to navigate market complexities and uncover opportunities that deliver consistent, long-term value for our family."
          }
        />
        </SmoothScroll>
        <SmoothScroll>
          <Rightimageconten />
        </SmoothScroll>
        <SmoothScroll>
          {/* <Patner /> */}
        </SmoothScroll>
        <SmoothScroll>
          <Founderwords />
        </SmoothScroll>
        <SmoothScroll>
          <Mapcontact />
        </SmoothScroll>
      </div>
    </Main>
  );
};

export default Home;
