import React from "react";
import { format } from "date-fns";
import axios from "axios";
import { useDispatch } from "react-redux";
import { EditOpen } from "../../redux/AdminCareerSlice";
const CareerTable = ({ search, JobData, getData }) => {
  const jobs = [
    {
      id: 1,
      title: "Software Engineer",
      type: "Full-time",
      location: "San Francisco, CA",
      addedOn: "2022-01-31",
      updatedOn: "2022-02-15",
    },
    {
      id: 2,
      title: "UX Designer",
      type: "Contract",
      location: "New York, NY",
      addedOn: "2022-02-01",
      updatedOn: "2022-02-10",
    },
    // Add more dummy data as needed
  ];

  const dispatch = useDispatch();
  const jobDataDelete = async (PID) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/detail/delete-data/:${PID}`
      );

      getData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="my-5">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border">No</th>
            <th className="py-2 px-4 border">Title</th>
            <th className="py-2 px-4 border">Type</th>
            <th className="py-2 px-4 border">Location</th>
            <th className="py-2 px-4 border">Added On</th>
            <th className="py-2 px-4 border">Updated On</th>
            <th className="py-2 px-4 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {JobData.filter((item) =>
            item.jobTitle.toLowerCase().includes(search.toLowerCase())
          ).map((job, index) => (
            <tr key={job.id} className={index % 2 === 0 ? "bg-gray-100" : ""}>
              <td className="py-2 px-4 border">{index + 1}</td>
              <td className="py-2 px-4 border">{job.jobTitle}</td>
              <td className="py-2 px-4 border">{job.jobType}</td>
              <td className="py-2 px-4 border">{job.jobLocation}</td>
              <td className="py-2 px-4 border">
                {" "}
                {format(new Date(job.createdAt), " dd MMMM , yyyy ")}
              </td>
              <td className="py-2 px-4 border">
                {" "}
                {format(new Date(job.updatedAt), " dd MMMM , yyyy ")}
              </td>
              <td className="py-2 px-4 border">
                {/* Add your action buttons or links here */}
                <button
                  className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                  onClick={() => {
                    dispatch(EditOpen(job._id));
                  }}
                >
                  Edit
                </button>
                <button
                  className="ml-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-700"
                  onClick={() => {
                    jobDataDelete(job._id);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CareerTable;
