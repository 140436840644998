import React, { useEffect, useState } from "react";
import Main from "../Main";
import { BsChevronCompactDown } from "react-icons/bs";
import Banner from "../Components/Banner";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useLocation } from "react-router-dom";
const FAQ = () => {
  const [Index, setIndex] = useState(false);
  const array = [1, 2, 3, 4, 5];
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(500, 0);
  }, [pathname]);
  return (
    <Main>
      <Banner heading={"FAQ"} />
      <div className="flex justify-center items-center">
        <div className="w-[90%] p-5 max-sm:p-0  mt-20 space-y-5">
          {array.map((item, index) => {
            return (
              <div
                className={`border-[#0A3C81] border-[2px] rounded-lg    p-4 py-6 max transition-all ease-in-out duration-500 ${
                  item == Index ? "bg-[#0A3C81]" : ""
                } `}
              >
                <div className="flex justify-between items-center">
                  <h1
                    className={`text-[25px]  font-semibold leading-[145%] tracking-[5%]  ${
                      Index == item ? " text-white" : "text-[#0A3C81]"
                    }`}
                  >
                    What is Krushi?
                  </h1>

                  {Index == item ? (
                    <IoIosCloseCircleOutline
                      className="text-white"
                      onClick={() => {
                        setIndex("");
                      }}
                    />
                  ) : (
                    <BsChevronCompactDown
                      onClick={() => {
                        setIndex(item);
                      }}
                    />
                  )}
                </div>
                {Index == item ? (
                  <p className={`text-left text-white p-5 para`}>
                    Krushi is a Public Charitable Trust designed to carry out
                    farming on an extensive scale using Natural & Sustainable
                    methods.
                  </p>
                ) : (
                  ""
                )}
              </div>
            );
          })}
          {/* <div className="border-[#0A3C81] border-[2px] rounded-lg   flex justify-between items-center p-4">
            <h1 className="text-[25px] text-[#0A3C81] font-semibold leading-[145%] tracking-[5%]">
              What is Krushi?
            </h1>
            <BsChevronCompactDown className="text-[#0A3C81] text-[25px]" />
          </div>
          <div className="border-[#0A3C81] border-[2px] rounded-lg   flex justify-between items-center p-4">
            <h1 className="text-[25px] text-[#0A3C81] font-semibold leading-[145%] tracking-[5%]">
              What is Krushi?
            </h1>
            <BsChevronCompactDown className="text-[#0A3C81] text-[25px]" />
          </div>
          <div className="border-[#0A3C81] border-[2px] rounded-lg   flex justify-between items-center p-4">
            <h1 className="text-[25px] text-[#0A3C81] font-semibold leading-[145%] tracking-[5%]">
              What is Krushi?
            </h1>
            <BsChevronCompactDown className="text-[#0A3C81] text-[25px]" />
          </div> */}
        </div>
      </div>
    </Main>
  );
};

export default FAQ;
