import React, { useState } from "react";

const milestoneYears = [
  {
    id: 1,
    year: 2016,
    point1: "Started Private Investing. Invested into 3 Private investments.",
  },
  {
    id: 2,
    year: 2017,
    point1: "Invested into 4 Private investments.",
  },

  {
    id: 3,
    year: 2018,
    point1: "Invested into 1 Private Investment. ",
  },

  {
    id: 4,
    year: 2021,
    point1: "Exited 1 Private investment.",
  },
  {
    id: 5,
    year: 2022,
    point1:
      "Divested polyesters business and started actively investing into various asset classes which generate sustainable risk adjusted returns.",
  },
];
const Mobiletimeline = () => {
  const [activeYear, setActiveYear] = useState(2016);
  const [show, setShow] = useState(false);

  const handleYearClick = (year) => {
    setActiveYear(year);
  };
  const [isClicked, setIsClicked] = useState(false);

  const [buttons, setButtons] = useState(Array(5).fill(false));

  const handleClick = (index) => {
    const newButtons = [...buttons];
    newButtons[index] = true;
    setButtons(newButtons);
  };

  return (
    <div className=" p-4">
      <h1 className="text-6xl font-bold mb-4 text-center pt-10">Timeline</h1>
      <div className="grid lg:flex-row space-y-4 lg:space-x-4">
        <div className="">
          {milestoneYears.map((x) => (
            <div
              key={x.id}
              className={`px-10 max-sm:px-0 max-sm:pl-5 mb-4 text-left ${
                activeYear === x.year ? "block" : "hidden"
              }`}
            >
              <h2 className="text-lg font-semibold">{x.year}</h2>
              <ul
                className={`list-disc list-outside h-full flex-col gap-3 w-full max-xl:w-full `}
              >
                {x.point1 && <li className="para">{x.point1}</li>}
                {x.point2 && <li className="para">{x.point2}</li>}
                {x.point3 && <li className="para">{x.point3}</li>}
                {x.point4 && <li className="para">{x.point4}</li>}
              </ul>
              {/* Add content for each milestone year */}
            </div>
          ))}
        </div>
        <hr className="w-full h-2 bg-gray-400 px-10 m-0" />
        <div className="flex overflow-x-scroll">
          {milestoneYears.map((x) => (
            <button
              key={x.id}
              onClick={() => handleYearClick(x.year)}
              className={`block p-2 w-full  ${
                activeYear === x.year ? " text-white bg-[#0B3D81]" : ""
              } rounded-lg mb-2`}
            >
              {x.year}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mobiletimeline;
