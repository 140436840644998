import React, { useState, useEffect, useRef } from 'react';


import 'tailwindcss/tailwind.css';
import { gsap } from "gsap";

const Timeline = () => {

    const elementRef = useRef(null);
    const [activeYear, setActiveYear] = useState(false);
    const [activeYear2, setActiveYear2] = useState(false);
    const [activeYear3, setActiveYear3] = useState(true);
    const [activeYear4, setActiveYear4] = useState(false);
    const [activeYear5, setActiveYear5] = useState(false);
    const btn1 = useRef(null);
    const btn2 = useRef(null);
    const btn3 = useRef(null);
    const btn4 = useRef(null);
    const btn5 = useRef(null);
    const str1 = useRef(null);
    const str2 = useRef(null);
    const str3 = useRef(null);
    const str4 = useRef(null);
    const str5 = useRef(null);

    // useEffect(() => {
    //     const elements = myRef.current.querySelectorAll('.my-class');
    //     // ... do something with elements
    //   }, []);

    useEffect(() => {
        gsap.to(elementRef.current, {
            rotate: 0,
            ease: "Expo.easeInOut",
            duration: 4,// Move the element 100 pixels down




        });
        gsap.to(str1.current, {

        })
    }, []);

   
    function rot1() {
        str1.current.style.transform = 'rotate(0deg)';
        str2.current.style.transform = 'rotate(20deg)';
        str3.current.style.transform = 'rotate(40deg)';
        str4.current.style.transform = 'rotate(60deg)';

        str5.current.style.transform = 'rotate(90deg)';
        str1.current.style.transitionDuration = '2s';
        str2.current.style.transitionDuration = '2s';
        str3.current.style.transitionDuration = '2s';
        str4.current.style.transitionDuration = '2s';
        str5.current.style.transitionDuration = '2s';
        setActiveYear(true);
        setActiveYear2(false);
        setActiveYear3(false);
        setActiveYear4(false);
        setActiveYear5(false);


    }
    function rot2() {
        str2.current.style.transform = 'rotate(0deg)';
        str1.current.style.transform = 'rotate(-20deg)';
        str3.current.style.transform = 'rotate(20deg)';
        str4.current.style.transform = 'rotate(40deg)';
        str5.current.style.transform = 'rotate(60deg)';
        str1.current.style.transitionDuration = '2s';
        str2.current.style.transitionDuration = '2s';
        str3.current.style.transitionDuration = '2s';
        str4.current.style.transitionDuration = '2s';
        str5.current.style.transitionDuration = '2s';
        setActiveYear(false);
        setActiveYear2(true);
        setActiveYear3(false);
        setActiveYear4(false);
        setActiveYear5(false);

    }
    function rot3() {
        str3.current.style.transform = 'rotate(0deg)';
        str1.current.style.transform = 'rotate(-40deg)';
        str2.current.style.transform = 'rotate(-20deg)';
        str4.current.style.transform = 'rotate(20deg)';
        str5.current.style.transform = 'rotate(40deg)';
        str1.current.style.transitionDuration = '2s';
        str2.current.style.transitionDuration = '2s';
        str3.current.style.transitionDuration = '2s';
        str4.current.style.transitionDuration = '2s';
        str5.current.style.transitionDuration = '2s';
        setActiveYear(false);
        setActiveYear2(false);
        setActiveYear3(true);
        setActiveYear4(false);
        setActiveYear5(false);
    }
    function rot4() {
        str4.current.style.transform = 'rotate(0deg)';
        str1.current.style.transform = 'rotate(-60deg)';
        str2.current.style.transform = 'rotate(-40deg)';
        str3.current.style.transform = 'rotate(-20deg)';
        str5.current.style.transform = 'rotate(20deg)';
        str1.current.style.transitionDuration = '2s';
        str2.current.style.transitionDuration = '2s';
        str3.current.style.transitionDuration = '2s';
        str4.current.style.transitionDuration = '2s';
        str5.current.style.transitionDuration = '2s';
        setActiveYear(false);
        setActiveYear2(false);
        setActiveYear3(false);
        setActiveYear4(true);
        setActiveYear5(false);
    }
    function rot5() {
        str5.current.style.transform = 'rotate(0deg)';
        str1.current.style.transitionDuration = '2s';
        str2.current.style.transitionDuration = '2s';
        str3.current.style.transitionDuration = '2s';
        str4.current.style.transitionDuration = '2s';
        str5.current.style.transitionDuration = '2s';
        str1.current.style.transform = 'rotate(-90deg)';
        str2.current.style.transform = 'rotate(-60deg)';
        str3.current.style.transform = 'rotate(-40deg)';
        str4.current.style.transform = 'rotate(-20deg)';
        setActiveYear(false);
        setActiveYear2(false);
        setActiveYear3(false);
        setActiveYear4(false);
        setActiveYear5(true);
    }

    // const [currentContent, setCurrentContent] = useState(1);

    // const timelineData = [
    //     { id: 1, date: '2016', content: 'Started Private Investing. Invested into 3 Private investments.' },
    //     { id: 2, date: '2017', content: 'Invested into 4 Private investments.' },
    //     { id: 3, date: '2018', content: 'Invested into 1 Private Investment.' },
    //     { id: 4, date: '2021', content: 'Exited 1 Private investment.' },
    //     { id: 5, date: '2022', content: 'Divested polyesters business and started actively investing into various asset classes which generate sustainable risk adjusted returns.' },
    // ];

    return (

        <div className="timline my-32 max-md:hidden ">
            
            <div ref={elementRef} id='cricle' className='cricle  '>
            <div id="panel">
                <button ref={btn1} onClick={rot1} className='mnbutton'>1</button>
                <button ref={btn2} onClick={rot2} className='mnbutton'>2</button>
                <button ref={btn3} onClick={rot3} className='mnbutton'>3</button>
                <button ref={btn4} onClick={rot4} className='mnbutton'>4</button>
                <button ref={btn5} onClick={rot5} className='mnbutton'>5</button>
            </div>

                <div ref={str1} id='' className='stripe str1 '>
                    <div id='' className="first">
                        <div className='pointer flex justify-center items-center'>
                            <div className='dot'></div>
                        </div>

                    </div>
                    <div className="sec flex">
                        <div onClick={rot1} >
                            <p className="smcicle year">2016</p>
                        </div>
                        <p className='p1 flex justify-center items-center mx-16'>{activeYear ? "Started Private Investing. Invested into 3 Private investments.":""}</p>
                    </div>
                </div>
                <div ref={str2} className='stripe str2'>
                    <div className="first">
                        <div className='pointer flex justify-center items-center'>
                            <div className='dot' ></div>
                        </div>
                    </div>
                    <div onClick={rot2} className="sec flex">
                        <div className="">
                            <p className='smcicle year'>2017</p>
                        </div>
                        <p className='flex justify-center items-center px-20'>{activeYear2 ? "Invested into 4 Private investments.":""}</p>
                    </div>
                </div>
                <div ref={str3} className='stripe str3'>
                    <div className="first">
                        <div className='pointer flex justify-center items-center'>
                            <div className='dot' ></div>
                        </div>

                    </div>
                    <div className="sec flex">
                        <div onClick={rot3} className="">
                            <p className=' smcicle year'>2018</p>
                        </div>
                        <p className='flex justify-center items-center px-20'>{activeYear3 ?"Invested into 1 Private Investment.":""}</p>
                    </div>
                </div>
                <div ref={str4} className='stripe str4'>
                    <div className="first">
                        <div className='pointer flex justify-center items-center'>
                            <div className='dot' ></div>
                        </div>
                    </div>
                    <div className="sec flex">
                        <div onClick={rot4} className="">
                            <p className=' smcicle year'>2021</p></div>
                        <p>
                        <p className='flex justify-center items-center px-20'> {activeYear4 ?"Exited 1 Private investment.":""}</p>
                        </p>
                    </div>
                </div>
                <div ref={str5} className='stripe str5'>
                    <div className="first">
                        <div className='pointer flex justify-center items-center'>
                            <div className='dot' ></div>
                        </div>
                    </div>
                    <div className="sec flex">
                        <div onClick={rot5} className="">
                            <p className='smcicle year'>2022</p>
                        </div>
                        <p className='flex justify-center items-center px-10'>{activeYear5 ?"Divested polyesters business and started actively investing into various asset classes which generate sustainable risk adjusted returns.":""}</p>
                    </div>
                </div>

            </div>
            
         
        </div>
    );
};

export default Timeline;
