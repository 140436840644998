import React from "react";
// import { addData } from "../../redux/AdminCareerSlice";
import { useDispatch } from "react-redux";

const Heading = ({ heading, setIsFormOpen, IsFormOpen, add, addData }) => {
  const dispatch = useDispatch();
  return (
    <div className="bg-[#093E86] flex justify-between p-2 rounded-t-lg ">
      <div className="text-white text-lg">{heading}</div>
        {add ? (
      <div className="border-[1px] p-1 bg-white rounded-lg cursor-pointer">
          <span
            className="font-bold"
            onClick={() => {
              dispatch(addData());
            }}
          >
            Add New
          </span>
      </div>
        ) : (
          ""
        )}
    </div>
  );
};

export default Heading;
