import React from 'react'

const Promotor = () => {
    return (
        <div className=''>
            {/* <ul><li className='py-2 font-semibold list-inside'> Promoters</li></ul> */}
            <p className='text-3xl text-[#093E86] py-2'>Our Promoters</p>
            <div className='grid grid-cols-3 py-5 max-md:py-10 px-2  gap-2 max-xl:gap-10 max-lg:grid-cols-2  max-md:grid-cols-1 '>
                <div className='  w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full  m-auto relative '>
                        <img className='rounded-lg max-lg:my-2' src='/images/Shri_Ramu_Raman_Agarwal.jpg' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Ramu Raman Agarwal</p>
                    
                </div>
                <div className=' w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative '>
                        <img className='rounded-lg ' src='/images/shri Bankesh Agarwal ji.jpg' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Bankesh Agarwal</p>
                    
                </div>
                <div className='  w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full  m-auto relative'>
                        <img className='rounded-lg max-lg:my-2' src='/images/Ajay_Agarwal.png' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Ajay Agarwal</p>
                    
                </div>
                <div className='  w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative  '>
                        <img className='rounded-lg ' src='/images/Shri_Manoj_Agarwal.jpg' alt=''/>
                    </div >
                    <p className='font-semibold text-2xl pt-4'>Shri Manoj Agarwal</p>
                    
                </div>
                <div className=' w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative '>
                        <img className='rounded-lg ' src='/images/Vikas_Agarwal.png' alt=''/>
                    </div >
                    <p className='font-semibold text-2xl pt-4'>Shri Vikas Agarwal</p>
                    
                </div>
                <div className='  w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative '>
                        <img className='rounded-lg ' src='/images/Shri_Sarthak_Agarwal.jpg' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Sarthak Agarwal</p>
                    
                </div>
                <div className=' w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative'>
                        <img className='rounded-lg ' src='/images/Nihit_Agarwal.png' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Nihit Agarwal</p>
                    
                </div>
                
                
               
               
                
            </div>
            {/* <div className='grid grid-cols-3 py-5 max-md:py-10 px-2  gap-2 max-xl:gap-10 max-lg:grid-cols-2  max-md:grid-cols-1  '>
                <div className='  w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full  m-auto relative '>
                        <img className='rounded-lg max-lg:my-2' src='/images/Shri_Ramu_Raman_Agarwal.jpg' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Ramu Raman Agarwal</p>
                    
                </div>
                <div className='  w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative  '>
                        <img className='rounded-lg ' src='/images/Shri_Manoj_Agarwal.jpg' alt=''/>
                    </div >
                    <p className='font-semibold text-2xl pt-4'>Shri Manoj Agarwal</p>
                    
                </div>
                <div className='  w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative  '>
                        <img className='rounded-lg ' src='/images/Shri_Sarthak_Agarwal.jpg' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Sarthak Agarwal</p>
                    
                </div>
                <div className=' w-fit px-[1rem] py-[1rem] my-5 rounded-lg m-auto shadow-[0px_0px_5px_0px]'>
                    <div className='bg-white rounded-full m-auto relative '>
                        <img className='rounded-lg ' src='/images/shri Bankesh Agarwal ji.jpg' alt=''/>
                    </div>
                    <p className='font-semibold text-2xl pt-4'>Shri Bankesh Agarwal</p>
                    
                </div>
               
                
            </div> */}
        </div>
    )
}

export default Promotor