import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const CareerForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    message: "",
    file: null, // Keep file as an object
  });

  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: file,
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate Name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email is required";
      isValid = false;
    }

    // Validate Contact Number
    const phoneRegex = /^[0-9]+$/;
    if (
      !formData.contactNumber.trim() ||
      !phoneRegex.test(formData.contactNumber)
    ) {
      newErrors.contactNumber = "Valid contact number is required";
      isValid = false;
    }

    // Validate Message
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setloading(true);
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("contactNumber", formData.contactNumber);
        formDataToSend.append("message", formData.message);
        formDataToSend.append("file", formData.file);

        // Using axios to send the form data
        const { data } = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/auth/request-for-job`,
          formDataToSend
        );

        if (data && data.success) {
          setloading(false);
          setFormData({
            name: "",
            email: "",
            subject: "",
            contactNumber: "",
            message: "",
            additionalField: "",
          });
          alert("Form Submitted...");
        } else {
          setloading(false);
          alert(data.message);
        }
      } catch (error) {
        alert("Something went wrong!");
        console.error(error);
      }
    } else {
      alert("Form is invalid. Please fix the errors.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  return (
    <section className="my-20">
      <div>
        <form onSubmit={handleSubmit} className="flex flex-col items-start ">
          <h1 className="text-[50px] max-md:text-[30px] max-sm:text-[20px] text-[#093E86] font-bold leading-normal text-left  w-full  my-5">
            Apply Now
          </h1>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-6 gap-4 w-full ">
            {/* Name Field */}
            <div className="w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Your Name
              </label>
              <br />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Your Name here"
                className="px-3 p-2 outline-none border-[1px] w-full placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.name && <div className="text-red-500">{errors.name}</div>}
            </div>

            {/* Email Field */}
            <div className="w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Your Email
              </label>
              <br />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Your Email here "
                className="px-3 p-2 border-[1px] outline-none w-full placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.email && (
                <div className="text-red-500">{errors.email}</div>
              )}
            </div>

            {/* Contact Number Field */}
            <div className="col-span-2 max-md:col-span-1 w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal mb-2"
              >
                Your Contact Number
              </label>
              <br />
              <input
                type="text"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
                placeholder="Your Phone here "
                className="px-3 p-2 border-[1px] outline-none border-[#DEE2E6] w-full placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.contactNumber && (
                <div className="text-red-500">{errors.contactNumber}</div>
              )}
            </div>

            {/* Message Field */}
            <div className="col-span-2 max-md:col-span-1 w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Message
              </label>
              <br />
              <textarea
                type="text"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Tell us a few words"
                className="px-3 p-2 border-[1px] w-full outline-none placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              ></textarea>
              {errors.message && (
                <div className="text-red-500">{errors.message}</div>
              )}
            </div>

            <div
              {...getRootProps()}
              className={`bg-[#EFEFEF] border-[4px] border-dashed p-10 text-center flex justify-center items-center gap-3 w-full col-span-1 md:col-span-2  px-10 py-10 y ${
                (isDragActive ? "border-green-500" : "border-gray-300",
                formData.file ? "border-green-500 " : "")
              } `}
            >
              <input {...getInputProps()} />

              {isDragActive ? (
                <h1
                  className={`text-base text-[#afafaf] ${
                    isDragActive ? "border-green-500" : "border-gray-300"
                  } font-sans font-bold leading-[100%] tracking-[2px] text-green-500`}
                >
                  DROP TO UPLOAD YOUR RESUME{" "}
                  <span className="text-base   font-sans font-bold leading-[100%] tracking-[2px] text-black ">
                    {" "}
                    OR BROWSE
                  </span>
                </h1>
              ) : (
                <h1
                  className={`text-base text-[#afafaf] ${
                    isDragActive ? "border-green-500" : "border-gray-300"
                  } font-sans font-bold leading-[100%] tracking-[2px]`}
                >
                  DROP TO UPLOAD YOUR RESUME{" "}
                  <span className="text-base  font-sans font-bold leading-[100%] tracking-[2px] text-black ">
                    {" "}
                    OR BROWSE
                  </span>
                </h1>
              )}
            </div>
            {/* Submit Button */}
            <div className="flex justify-start">
            <button
      type="submit"
      className={`text-[20px] font-bold leading-normal text-white bg-[#093E86] py-2 px-3 tracking-[2px] relative rounded-md ${
        loading ? ' cursor-not-allowed' : ''
      }`}
      onClick={handleSubmit}
      disabled={loading}
    >
      {loading ? (
   <div className="flex gap-2">
        <div className="animate-spin rounded-full border-t-4 border-blue-500 border-t-blue-500 h-6 w-6 mx-auto"></div>
         <span>loading</span>
   </div>
 ) : (
        'Submit'
      )}
    </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CareerForm;
