import React, { useEffect, useState } from "react";
import AdminMain from "./AdminMain";
import Heading from "./Component/Heading";
import { IoIosRefresh } from "react-icons/io";
import CareerTable from "./Component/CareerTable";
import AdminCareerForm from "./Component/AdminCareerForm";
import { useSelector } from "react-redux";
import axios from "axios";
import EditForm from "./Component/EditForm";
import { addData } from "../redux/AdminCareerSlice";

export const AdminCareer = () => {
  const [loading, setLoading] = useState(false);
  const [IsFormOpen, setIsFormOpen] = useState(false);
  const isOpen = useSelector((state) => state.Admincareer.IsOpen);
  const IsEdit = useSelector((state) => state.Admincareer.IsEdit);
  const [JobData, setJobData] = useState([]);
  const [search, setSearch] = useState("");
  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/detail/getJobdetail`
      );
      setJobData(data.jobdetails);
      setLoading(false);
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleRefresh = () => {
    setLoading(!loading);
    getData();
  };

  const getdata = () => setIsFormOpen(!IsFormOpen);
  return (
    <AdminMain>
      <div className="p-5 ">
        {isOpen ? <AdminCareerForm getData={getData} /> : ""}
        {IsEdit ? <EditForm getData={getData} /> : ""}
        <Heading
          heading={"Job Posts Information"}
          setIsFormOpen={setIsFormOpen}
          IsFormOpen={IsFormOpen}
          addData={addData}

          add={true}
        />

        <div className="p-2 border-[1px] rounded-b-lg ">
          <div className="flex gap-3 border-b-[1px] pb-2 border-gray-100 mt-2 px-5 ">
            <div
              className="bg-[#06b4d2] w-fit p-2 rounded-lg border-2 "
              onClick={handleRefresh}
            >
              <IoIosRefresh
                className={`text-white text-2xl ${
                  loading ? "animate-spin " : ""
                } `}
              />
            </div>
            <div>
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search..."
                value={search}
                className="pr-16 placeholder:text-gray-400 placeholder:text-xl outline-none placeholder:font-light border-[1px] p-2 rounded-lg"
                onChange={(e) => {
                  const searchnew = e.target.value;
                  setSearch(searchnew);
                }}
              />
            </div>
          </div>
          <CareerTable search={search} getData={getData} JobData={JobData} />
        </div>
      </div>
    </AdminMain>
  );
};
