import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Data: [],

  EditID: "",

  IsOpen: false,
  IsEdit: false,
};
const CareerSlice = createSlice({
  name: "Admincareer",
  initialState,
  reducers: {
    addData: (state, action) => {
      state.IsOpen = !state.IsOpen;
    },

    EditOpen: (state, action) => {
      state.IsEdit = !state.IsEdit;
      state.EditID = action.payload;
    },
  },
});

export const { addData, Data, EditOpen } = CareerSlice.actions;

export default CareerSlice.reducer;
