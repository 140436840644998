import React from "react";
import { GoDotFill } from "react-icons/go";

const Global = () => {
  return (
    <div className="my-32">
      <ul className="flex items-center justify-center gap-4 max-sm:gap-3">
        {/* <GoDotFill className="text-2xl max-xl:text-xl max-md:text-lg" />{" "} */}
        <li className=" para text-black font-jost font-semibold text-center text-2xl">
          Global Presence
        </li>
      </ul>
      <p className="text-[50px] max-xl:text-[30px] font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] ">
        Our Investment system
      </p>
      <div
        className="  bg-center bg-cover bg-no-repeat h-[900px]"
        style={{ backgroundImage: "url('/images/World_map.jpg')" }}
      >
        <div className="absolute">
          <div className="grid grid-cols-3 m-auto items-center py-[20%] gap-7 px-10 max-xl:grid-cols-2 max-md:grid-cols-1">
            <div className="text-left w-fit px-5 py-5 rounded-lg  shadow-[0px_0px_15px_0px] h-full">
              <div className=" h-[10vh] w-[10vw] m-auto  relative bottom-10 right-20 ">
                <img src="/images/sigapore-pin.png" alt="" />
              </div>
              <p className="font-semibold text-[22px] my-2">Singapore</p>
              <p className="text-[20px]">
                Samika Global Pte. Ltd: 17, Phillip Street, #05-01, Grand
                Building, Singapore (048695)
              </p>
            </div>
            <div className="text-left w-fit px-5 py-5 rounded-lg m-auto shadow-[0px_0px_15px_0px] h-full">
              <div className="h-[10vh] w-[10vw] m-auto relative bottom-10 right-20">
                <img src="/images/india-pin.png" alt="" />
              </div>
              <p className="font-semibold text-[22px] my-2">India</p>
              <p className="text-[20px]" >
                Shubhalakshmi Polyesters Ltd. 1101, Lodha Supremus, Tulsi Pipe
                Road, Lower Parel, Mumbai 400013
              </p>
            </div>
            <div className="text-left w-fit px-5 py-5 rounded-lg m-auto shadow-[0px_0px_15px_0px] h-full">
              <div className=" h-[10vh] w-[10vw] m-auto  relative bottom-10 right-20 ">
                <img src="/images/india-pin.png" alt="" />
              </div>
              <p className="font-semibold text-[22px] my-2">India</p>
              <p className="text-[20px]">
                1010 Rajhans Montessa, Dumas Rd, beside Le Meridien Hotel, near
                Airport, Surat, Gujarat 395007
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Global;
