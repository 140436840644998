import React, { useEffect, useState } from "react";
import Banner from "../Banner";
import { MdOutlineDone } from "react-icons/md";
import CareerForm from "../Career/CareerForm";
import Main from "../../Main";
import axios from "axios";

const CareerDetailPage = () => {
  const id = JSON.parse(localStorage.getItem("ID"));
  const [data, setdata] = useState([]);

  // console.log(List);
  const getCareerData = async () => {
    try {
      var res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/detail/get-single-data/${id}`
      );
      setdata(res.data.jobdetails);
      console.log(res.data.jobdetails);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getCareerData();
  }, []);
  return (
    <Main>
      <div>
        <Banner heading={data.jobTitle} />
        <div className="flex  flex-col  items-center my-20 max-sm:my-0">
          <div className="space-y-11 max-md:space-y-4 w-[80%] max-lg:w-[90%] max-sm:w-full max-sm:p-3">
            <div className="space-y-4">
              <h1 className="text-[50px] max-md:text-[30px] max-sm:text-[20px] text-[#093E86] font-bold leading-normal text-left w-[70%] max-sm:w-full  mt-5">
                {data.jobTitle}
              </h1>
            </div>{" "}
            <div className="text-left">
              <div dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
            <div>
              <CareerForm />
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default CareerDetailPage;

// Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab, pariatur.

