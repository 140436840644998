import React from "react";
import { GoDotFill } from "react-icons/go";

const Founderwords = () => {
  return (
    <div className="flex flex-col justify-center items-center ">
      <p className="text-[50px] max-xl:text-[30px] font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] ">
      Shubhalakshmi Family
      </p>
      <ul className="flex items-center gap-4">
        <GoDotFill className="text-2xl max-xl:text-xl max-md:text-base" />{" "}
        <li className=" para text-black font-jost font-semibold ">
          Chairman words
        </li>
        <GoDotFill className="text-2xl max-xl:text-xl max-md:text-base" />{" "}
      </ul>

      <div className="grid grid-cols-3 w-[90%] max-sm:w-full  max-md:grid-cols-1 max-md:gap-2  max-xl:gap-5 max-sm:px-3 max-sm:text-center">
        <div className="col-span-2 m-auto">
          <p className="para text-left ">
            <span className="text-6xl text-[#093E86] max-w-[900px]">“</span>
            Forget chasing the next quarterly gain. Forget fleeting hype and
            short-term trends. Today, I propose a bold vision: partnering to
            forge a future of sustainable wealth. A future where capital
            transcends mere numbers, becoming a force for positive change. A
            future where risk is managed, not gambled, and returns are measured
            not just in dollars, but in the impact we create.
            <br /><br/>
            This is not a call for charity, but for strategic investments. We
            seek partners who share our vision, who can offer us access to
            diverse landscapes:
            <br />
            <br />
            <ul className="list-disc ml-5">
              <li>
            Fixed income with a conscience.
            </li>
            <li>
            
            Private equity that empowers communities.
            </li><li>
            
            Public markets that drive innovation.
            </li>
            <li>
          
            Even direct investments in ventures shaping tomorrow.
            </li>
            </ul>
            
            <br />
            We are not novice investors. We demand rigorous risk management,
            intelligent diversification, and downside protection. We won't chase
            rainbows; we'll build sustainable portfolios for generations to
            come.
            {/*  But we are more than just investors. We are stewards, seeking
            partners who align with our values:
            <br />
            <br />
            Sustainability, not just profit.
            <br />
            Community impact, not just shareholder returns.
            <br />
            Technological advancements that benefit all.
            <br />
            Together, we can craft a new paradigm, where wealth becomes a
            catalyst for a brighter future.
            <br />
            <br />
            So, I invite the bold, the innovative, the forward-thinking. Let us
            forge a partnership, not just for financial gain, but for a legacy
            of sustainable prosperity, for our families, and for the world.{" "} */}
            <span className="flex justify-end text-6xl   text-jost text-[#093E86]">
              “
            </span>
          </p>
          <p className="text-[#093E86] text-2xl text-jost max-lg:text-xl max-sm:text-[15px] font-bold text-left">
            - Shri Ajay Agarwal
          </p>
        </div>
        <div className="m-auto ">
          <img
            className="rounded-2xl max-h-[80vh] "
            src="/images/Ajay_Agarwal.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Founderwords;
