import React, { useEffect, useState } from "react";
import { SlCalender } from "react-icons/sl";
import { FaUser } from "react-icons/fa";
import { AiOutlineComment } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { SetID } from "../../redux/BlogSlice";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
const BlogTemplate = ({ blog, getData }) => {
  const dispatch = useDispatch();

  const [imageURL, setImageURL] = useState("");

  const formattedDate = blog?.createdAt
    ? format(new Date(blog.createdAt), "dd MMMM yyyy")
    : ""; // Make sure to run this effect only once
  console.log(imageURL);
  return (
    <Link to="/blogdetail">
      <div
        className=""
        onClick={() => {
          dispatch(SetID(blog._id));
        }}
      >
        <div className="flex w-full max-lg:flex-col max-xl:w-full bg-[#F9F9F9]">
          <img
            src={`https://shubhalakshmi.s3.ap-south-1.amazonaws.com/${blog.file}`}
            alt="Blog Image"
            className="w-[366px] h-[266px] max-xl:w-[260px] max-xl:h-[260px] max-lg:w-full max-lg:h-full"
          />

          <div className="flex flex-col justify-between gap-3 p-5 max-lg:p-5 w-full">
            <h1 className="text-[20px] text-left max-lg:text-[12px] max-md:text-[10px] font-bold tracking-[1px] max-lg:tracking-[0.75px]">
              {blog.title}
            </h1>
            <p className="text-[20px] max-xl:text-[15px] text-left text-[#909090] max-lg:text-[12px] max-md:text-[10px] font-semibold tracking-[1px] max-lg:tracking-[0.75px] leading-[145%] ">
              {blog.introducation}
            </p>
            <ul className="flex justify-start gap-10 text-[12px] max-sm:text-[10px]">
              <li className="flex items-center gap-1">
                <SlCalender className="text-blue-800" />
                <span className="leading-[145%] font-bold">
                  {formattedDate}
                </span>
              </li>
              <li className="flex items-center gap-1">
                <FaUser className="text-blue-800" />
                <span className="leading-[145%] font-bold">Admin</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogTemplate;
