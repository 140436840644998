import React, { useEffect, useState } from "react";
import Banner from "../Banner";
import BlogTemplate from "./BlogTemplate";
import { BiSearchAlt } from "react-icons/bi";
import { SlCalender } from "react-icons/sl";
import { FaUser } from "react-icons/fa";
import { AiOutlineComment } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import Main from "../../Main";
import axios from "axios";
import { format } from "date-fns";
import { toast } from "react-toastify";

import DOMPurify from "dompurify";

const BlogDetailPage = () => {
  const [singleblog, setsingleBlog] = useState([]);
  const [imageURL, setImageURL] = useState("");
  console.log(singleblog);
  const PID = JSON.parse(localStorage.getItem("blogID"));
  console.log(PID);
  const getblogsingledata = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/blog/get-single-blog/${PID}`
      );
      console.log(data);
      setsingleBlog(data.blogdata);
    } catch (error) {
      toast(error.message);
    }
  };

  useEffect(() => {
    getblogsingledata();
  });
  const sanitizedContent = DOMPurify.sanitize(singleblog.content);
  const formattedDate = singleblog?.createdAt
    ? format(new Date(singleblog.createdAt), "dd MMMM yyyy")
    : "";
  return (
    <Main>
      <div>
        <Banner heading={" Google Analytics"} />

        <div className="flex max-md:flex-col-reverse justify-center gap-20 max-xl:gap-14 max-lg:gap-10 mt-36 max-lg:mt-20 p-4 max-sm:p-2">
          <div className="max-w-[768px]  ">
            <ul className="flex  text-[12px] gap-5 max-sm:gap-3 max-sm:text-[10px] mb-2">
              <li className="flex items-center gap-1">
                <SlCalender className="text-blue-800" />{" "}
                <span className=" leading-[145%] font-bold">
                  {formattedDate}
                </span>
              </li>
              <li className="flex items-center gap-1">
                <FaUser className="text-blue-800" />
                <span className="leading-[145%] font-bold">Admin</span>
              </li>
            </ul>
            <div className="mb-10">
              <img
                src={`https://shubhalakshmi.s3.ap-south-1.amazonaws.com/${singleblog.file}`}
                alt="#img"
                className="w-[768px] h-[510px] max-lg:h-[400px] max-sm:h-[310px]"
              />
            </div>

            <div className=" w-fit ">
              <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <div
                className="w-[351px] max-lg:w-[270px] max-md:w-full  bg-[#093E86] flex flex-col max-md:flex-row justify-center items-start gap-3 max-sm:gap-1
         p-10 max-lg:p-5 max-md:p-1 max-md:my-0"
              >
                <h1 className="text-white text-[20px] font-bold leading-[145%] text-left p-2 max-sm:p-1">
                  Search
                </h1>

                <div className="bg-[#FFF] flex items-center w-full justify-center p-5 max-md:p-2 my-3 max-md:my-0">
                  <input
                    type="text"
                    placeholder="SEARCH KEYWORD"
                    className="placeholder:text-[12px] p-1 outline-none  placeholder:font-bold w-full placeholder:leading-[145%] placeholder:tracking-[2.4px] placeholder:text-[#000]"
                  />
                  <BiSearchAlt className="text-[#093E86] " />
                </div>
              </div>
              <div
                className="w-[351px] max-lg:w-[270px] max-md:w-full  bg-white flex flex-col max-md:hidden justify-center items-start gap-3 max-sm:gap-1
         p-10 max-lg:p-5 max-md:p-1 max-md:my-0"
              >
                <h1 className="text-black text-[25px] font-semibold leading-[145%] text-left p-2 max-sm:p-1">
                  Categories
                </h1>

                <div className="bg-[#F9F9F9] flex items-center w-full justify-center p-5 max-md:p-2 my-3 max-md:my-0">
                  <input
                    type="text"
                    placeholder="SEARCH KEYWORD"
                    className="placeholder:text-[12px] p-1 outline-none  placeholder:font-bold bg-[#F9F9F9] w-full placeholder:leading-[145%] placeholder:tracking-[2.4px] placeholder:text-[#000]"
                  />
                  <BiSearchAlt className="text-[#093E86] " />
                </div>
              </div>

              <div className="space-y-3 recent block max-md:hidden ">
                <h1 className="text-black text-[25px] font-semibold  tracking-[1.25px] text-left">
                  Recent Posts
                </h1>
                <div className="w-[351px] max-lg:w-[270px]">
                  <img
                    src={`https://shubhalakshmi.s3.ap-south-1.amazonaws.com/${singleblog.file}`}
                    alt="Recent Post Image Description"
                    className=""
                  />
                  <div className="bg-[#F9F9F9] p-3">
                    <h1 className="text-black text-left text-[15px] max-sm:text-[10px]  font-medium leading-normal tracking-[0.75px]">
                      Tracking metrics are essential to evaluate the efficacy of
                      any project or campaign.
                    </h1>
                    <p className="text-[#093E86] text-left text-[12px] font-bold leading-[145%] tracking-[0.75px]">
                      16.01.23
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-10  max-md:hidden ">
                <div className="">
                  <h1 className="text-black text-[25px] font-medium leading-[145%] tracking-[1.25px] text-left">
                    Instagram
                  </h1>

                  <ul className="flex gap-2">
                    <li className=" rounded-[50px] bg-[#F9F9F9] p-2 tracking-[0.75px]">
                      Corporate
                    </li>
                    <li className=" rounded-[50px] bg-[#F9F9F9] p-2 tracking-[0.75px]">
                      Business
                    </li>
                  </ul>

                  <ul className="flex gap-2 mt-2">
                    <li className=" rounded-[50px] bg-[#F9F9F9] p-2 leading-6 tracking-[0.75px]">
                      Entertainment
                    </li>
                    <li className=" rounded-[50px] bg-[#F9F9F9] p-2 tracking-[0.75px]">
                      Innovations
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center my-20 max-sm:my-10 p-3">
          <div className="block max-lg:hidden max-md:block ">
            <h1 className="text-[#093E86] text-2xl font-bold text-left my-3">
              Related Posts
            </h1>
            <div className="grid grid-cols-3 max-xl:grid-cols-2 gap-x-20 max-sm:gap-x-3 gap-y-10 max-sm:gap-y-5">
              {[...Array(6)].map((_, index) => (
                <div key={index}>
                  <div className="space-y-3 recent ">
                    {/* <h1 className="text-black text-[25px] font-semibold  tracking-[1.25px] text-left">
                    Recent Posts
                  </h1> */}
                    <div className="w-[351px] max-lg:w-[270px] max-sm:w-fit">
                      <img
                        src={`https://shubhalakshmi.s3.ap-south-1.amazonaws.com/${singleblog.file}`}
                        alt="Recent Post Image Description"
                        className=""
                      />
                      <div className="bg-[#F9F9F9] p-3 max-sm:p-2">
                        <h1 className="text-black text-left text-[15px] max-sm:text-[10px]  font-medium leading-normal tracking-[0.75px] ">
                          Tracking metrics are essential to evaluate the
                          efficacy of any project or campaign.
                        </h1>
                        <p className="text-[#093E86] text-left text-[12px] font-extrabold leading-[145%] tracking-[0.75px] mt-1">
                          16.01.23
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <HandDegisn /> */}
      </div>
    </Main>
  );
};

export default BlogDetailPage;
