import React, { useEffect, useState } from "react";
import AdminMain from "./AdminMain";
import Heading from "./Component/Heading";
import { IoIosRefresh } from "react-icons/io";
import JobApplicationTable from "./Component/JobApplicationTable";
import axios from "axios";
import InqueryTable from "./Component/InqueryTable";

const AdminInquery = () => {
  const [loading, setLoading] = useState(false);
  const [application, setapplication] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);

  const [search, setSearch] = useState("");
  const getData = async () => {
    try {
      setRefreshing(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/auth/get-register-data`
      );
      setapplication(data.application);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleRefresh = () => {
    setLoading(!loading);
    getData();
  };
  return (
    <AdminMain>
      <div className="p-5 ">
        <Heading heading={"Inquery"} add={false} isRefreshing={isRefreshing} />

        <div className="p-2 border-[1px] rounded-b-lg ">
          <div className="flex gap-3 border-b-[1px] pb-2 border-gray-100 mt-2 px-5 ">
            <div
              className="bg-[#06b4d2] w-fit p-2 rounded-lg border-2 "
              onClick={handleRefresh}
            >
              <IoIosRefresh
                className={`text-white text-2xl ${
                  loading ? "animate-spin " : ""
                } `}
              />
            </div>
            <div>
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search..."
                value={search}
                className="pr-16 placeholder:text-gray-400 placeholder:text-xl outline-none placeholder:font-light border-[1px] p-2 rounded-lg"
                onChange={(e) => {
                  const searchnew = e.target.value;
                  setSearch(searchnew);
                }}
              />
            </div>
          </div>
          <InqueryTable search={search} application={application} />
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminInquery;
