import React from "react";
import { MdCall, MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { RiMapPin2Fill } from "react-icons/ri";

const Footer = () => {
  return (
    <div className="mt-24 bg-[#F3F7FC]">
      <div className=" py-10 px-4 grid grid-cols-5  max-xl:grid-cols-4 max-sm:grid-cols-2 max-small-sm:grid-cols-1   gap-10 max-lg:gap-5  ">
        <div className="flex justify-center items-start max-sm:justify-center max-small-sm:justify-center col-span-1 max-xl:col-span-1 max-small-sm:col-span-2  ">
          <img
            className=" h-[197px] max-md:h-[120px] w-full max-md:w-[120px]"
            src="/images/logos/shubhalakshmi-logo.png"
            alt=""
          />
        </div>

        <div className="flex flex-col items-center max-sm:items-center max-small-sm:items-start pl-1 justify-start col-span-1  ">
          <ul className="text-left grid grid-cols-1 gap-1 ">
            <li className=" footerHeading mb-2">Useful Link</li>

            <Link to="/about" className="footerlink">
              About
            </Link>
            {/* <li className="footerlink">Blog</li>
            <li className="footerlink">Career</li> */}
            <Link to="/contact" className="footerlink">
              Contact
            </Link>
            <Link to="/futureprospects" className="footerlink">
            Future Prospects
            </Link>
            {/* <Link to="/faq" className="footerlink">
              Faq
            </Link>
            <Link to="/privacy" className="footerlink">
              Privacy Policy
            </Link> */}
          </ul>
        </div>

        <div className="flex flex-col max-xl:hidden">
          <ul className="text-left  grid grid-cols-1 gap-2">
            <li className="footerHeading mb-2">Our Business</li>
            <li className="footerlink">
              <Link to="/Shubhalakshmi_polyesters">
                Shubhalakshmi Polyesters
              </Link>
            </li>
            <li className="footerlink">
              <Link to="/sunkon">Sunkon Energy Pvt Ltd</Link>
            </li>
            {/* <li className="footerlink">
              <Link to="/investment">Investment</Link>
            </li> */}
          </ul>
        </div>
        <div className="flex flex-col items-center justify-center max-sm:items-start  col-span-2  ">
          <ul className="grid grid-cols-1 gap-2">
            <li className="footerHeading mb-2">Contact us</li>
            <li className="flex gap-4 items-center ">
              <div className="bg-[#093E86] rounded-full p-[3px] ">
                <MdCall className="text-white text-[15px]" />
              </div>
              <span className="footerlink"> +91 8799451956</span>
            </li>
            <li className="flex gap-4 items-center ">
              <div className="bg-[#093E86] rounded-full p-[3px] ">
                <MdEmail className="text-white text-[15px]" />
              </div>
              <span className="footerlink">investment@shubhalakshmi.com</span>
            </li>
            <a
              href="https://maps.app.goo.gl/EpwmkiWRwJ4yXkD98"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <li className="flex gap-4 items-start">
                <div className="bg-[#093E86] rounded-full p-[3px] ">
                  <RiMapPin2Fill className="text-white text-[15px]" />
                </div>

                <p className="footerlink">
                  1010 Rajhans Montessa, Dumas Rd, <br />
                  beside Le Meridien Hotel, near Airport, Surat,
                  <br /> Gujarat 395007
                </p>
              </li>
            </a>
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <li className="flex gap-4 items-start">
                <div className="bg-[#093E86] rounded-full p-[3px] ">
                  <RiMapPin2Fill className="text-white text-[15px]" />
                </div>

                <p className="footerlink">
                Shubhalakshmi Polyesters Ltd. 1101 <br />
                Lodha Supremus, Tulsi Pipe Road,
                  <br /> Lower Parel, Mumbai 400013
                </p>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
