import React, { useEffect, useState } from "react";
import Banner from "../Components/Banner";
import ProfileTemplate from "../Components/Contact/ProfileTemplate";
import Main from "../Main";
import axios from "axios";

const Career = () => {
  const [jobData, setjobData] = useState([]);
  console.log(jobData);
  useEffect(() => {
    getCareerData();
  }, []);

  const getCareerData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/detail/getJobdetail`
      );
      const { data } = res;
      setjobData(data.jobdetails);
    } catch (error) {
      console.error("Error fetching job data:", error);
      alert("Something went wrong while fetching job data");
    }
  };

  return (
    <Main>
      <div>
        <Banner heading={"Career"} />

        <div className="p-32 max-xl:p-10 max-lg:p-5">
          <h1 className="text-[50px] max-md:text-[30px] max-sm:text-[20px] text-[#093E86] font-bold leading-normal text-left w-[70%] max-sm:w-full  mt-5">
            Available post
          </h1>{" "}
          <br />
          <br />
          <div>
            <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-10 max-xl:gap-8 max-lg:gap-5">
              {jobData.map((item) => {
                console.log(item);
                return <ProfileTemplate singleItem={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Career;
