import React, { useState } from "react";
import { FiChevronRight, FiHome } from "react-icons/fi";
import { FaAngleRight, FaBlog } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import AdminMain from "./AdminMain";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import ShubhalakshmiLogo from "./ShubhalakshmiLogo";

const AdminSidebar = ({ sideMenuIsExpand }) => {
  const [Isopen, setIsopen] = useState(false);
  return (
    <div className="w-full bg-[#093E86] overflow-hidden">
      {sideMenuIsExpand ? (
        <h1 className="text-white text-xl font-bold tracking-[2px] text-center p-5">
          Shubhalakshmi{" "}
        </h1>
      ) : (
        <div className="flex justify-center my-5">
          <ShubhalakshmiLogo />
        </div>
      )}

      <div className="mt-20 ">
        {sideMenuIsExpand ? (
          <ul className="flex flex-col justify-start gap-2 ">
            <Link
              to="/admin/dashboard"
              className="text-white hover:text-black text-xl font-normal font-jost tracking-[1px] flex justify-between gap-3 items-center hover:bg-slate-50 bg-opacity-15  transition-all ease-in-out duration-150 p-2 px-5"
            >
              <div className="flex items-center  gap-3">
                <FiHome className="hover:text-blacktext-base" />{" "}
                <span>Dashboard</span>{" "}
              </div>
            </Link>
            <Link>
              <div
                className="text-white hover:text-black text-xl font-normal font-jost tracking-[1px]  hover:bg-slate-50 bg-opacity-15 
            transition-all ease-in-out duration-150 p-2 px-5"
                onClick={() => {
                  setIsopen(!Isopen);
                }}
              >
                <div className="flex justify-between gap-3 items-center">
                  <div className="flex items-center justify-between gap-3">
                    <AiOutlineAppstoreAdd className="hover:text-blacktext-base" />{" "}
                    <span>Career</span>{" "}
                  </div>
                  {!Isopen ? <FiChevronRight /> : <IoIosArrowDown />}
                </div>
              </div>
              {Isopen ? (
                <ul className=" flex flex-col py-3  ">
                  <Link
                    to="/admin/jobpost"
                    className="text-white hover:text-black text-xl  font-normal text-start font-jost tracking-[1px]  hover:bg-slate-100 bg-opacity-15 
             transition-all ease-in-out duration-150 p-2 px-5"
                    onClick={() => {
                      setIsopen(false);
                    }}
                  >
                    Job Post
                  </Link>

                  <Link
                    to="/admin/jobapplication"
                    className="text-white hover:text-black text-xl font-normal text-start font-jost tracking-[1px]  hover:bg-slate-50 bg-opacity-15 
             transition-all ease-in-out duration-150 p-2 px-5"
                    onClick={() => {
                      setIsopen(false);
                    }}
                  >
                    Job Application
                  </Link>
                </ul>
              ) : (
                ""
              )}
            </Link>
            <Link
              to="/admin/adminblog"
              className="text-white hover:text-black text-xl  font-normal font-jost tracking-[1px] flex justify-between gap-3 items-center hover:bg-slate-50 bg-opacity-15  transition-all ease-in-out duration-150 p-2 px-5"
            >
              <div className="flex justify-between gap-3">
                <FaBlog className="hover:text-blacktext-base" />{" "}
                <span>Blog</span>{" "}
              </div>
              {/* {!Isopen1 ? <FiChevronRight /> : <IoIosArrowDown />} */}
            </Link>
            <Link
              to="/admin/adminInquery"
              className="text-white hover:text-black text-xl  font-normal font-jost tracking-[1px] flex justify-between gap-3 items-center hover:bg-slate-50 bg-opacity-15  transition-all ease-in-out duration-150 p-2 px-5"
            >
              <div className="flex justify-between gap-3">
                <FaBlog className="hover:text-blacktext-base" />{" "}
                <span>Inquery</span>{" "}
              </div>
              {/* {!Isopen1 ? <FiChevronRight /> : <IoIosArrowDown />} */}
            </Link>
          </ul>
        ) : (
          <ul className="flex flex-col items-center justify-center gap-5 p-3">
            <li className="text-white hover:text-black text-xl  font-normal font-jost tracking-[1px] flex justify-center w-fit gap-3 items-center hover:bg-slate-50 bg-opacity-15 rounded-xl transition-all ease-in-out duration-150 p-2 px-5">
              <FiHome className="hover:text-blacktext-base" />{" "}
            </li>
            <li className="text-white hover:text-black text-xl  font-normal font-jost tracking-[1px] flex justify-between gap-3 items-center hover:bg-slate-50 bg-opacity-15 rounded-xl transition-all ease-in-out duration-150 p-2 px-5">
              <AiOutlineAppstoreAdd className="hover:text-blacktext-base" />{" "}
            </li>
            <li className="text-white hover:text-black text-xl  font-normal font-jost tracking-[1px] flex justify-between gap-3 items-center hover:bg-slate-50 w-fit bg-opacity-15 rounded-xl transition-all ease-in-out duration-150 p-2 px-5">
              <FaBlog className="hover:text-blacktext-base" />{" "}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminSidebar;
