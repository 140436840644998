import axios from "axios";
import React from "react";
import { blogEditOpen } from "../../redux/AdminBlogSlice";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

const Blogtable = ({ search, blogData, getData }) => {
  const dispatch = useDispatch();

  const blogDataDelete = async (PID) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/blog/blogdelete/${PID}`
      );
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="my-5">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border">No</th>
              <th className="py-2 px-4 border">Date</th>
              <th className="py-2 px-4 border">Title</th>
              {/* <th className="py-2 px-4 border">Heading</th> */}
              <th className="py-2 px-4 border">Postby</th>
              <th className="py-2 px-4 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {blogData
              .filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase())
              )
              .map((item, index) => (
                <tr
                  key={item.id}
                  className={index % 2 === 0 ? "bg-gray-100" : ""}
                >
                  <td className="py-2 px-4 border">{index + 1}</td>
                  <td className="py-2 px-4 border">
                    {" "}
                    {format(new Date(item.createdAt), " dd MMMM , yyyy ")}
                  </td>
                  <td className="py-2 px-4 border">{item.title}</td>
                  {/* <td className="py-2 px-4 border">{item.heading}</td> */}
                  <td className="py-2 px-4 border">Admin</td>
                  <td className="py-2 px-4 border">
                    {/* Add your action buttons or links here */}
                    <button
                      className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                      onClick={() => {
                        dispatch(blogEditOpen(item._id));
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="ml-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-700"
                      onClick={() => {
                        blogDataDelete(item._id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Blogtable;
