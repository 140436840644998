import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { motion } from "framer-motion";
const Homebanner = () => {
  return (
    <div>
      <div
        className="bg-cover bg-center bg-no-repeat h-[847px]  shadow-xl  max-lg:h-[600px] max-sm:h-[400px]  "
        style={{ backgroundImage: "url('/images/home-banner-img.png')" }}
      >
        <div className="bg-color h-full w-full   flex  justify-center leading-9">
          <div className=" text-white h-full w-[80%] max-sm:w-[90%] flex items-center   mt-10 max-sm:mt-5">
            <div className="text-left space-y-2 ">
              <p className="text-[25px] font-jost  font-[500] max-sm:text-[10px]">
              Welcome To Shubhalakshmi
              </p>
              <p className="text-[48px] font-jost max-lg:text-[50px] max-sm:text-[25px]  w-[70%]  max-xl:w-full max-sm:w-full leading-[100%] max-sm:leading-[30px] font-[600] ">
                Investment is the best key success in life
              </p>
              <p className="text-[30px] font-jost leading-normal font-[500] max-xl:text-[20px] max-sm:text-[15px]">
                Let’s Grow Wealth Togather.
              </p>

              <div className="flex gap-5 my-4">
                {/* <button className="bg-white text-black font-[500]  group py-2 px-6 max-sm:py-1 rounded-lg max-sm:rounded-3xl max-sm:text-[12px]">
                  <Link to={"/about"} className="flex items-center gap-2">
                    More{" "}
                    <motion.span
                      animate={{ x: [20, 5, 20], y: 0 }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "reverse",
                      }}
                      className="inline-block "
                    >
                      <FaArrowRightLong />
                    </motion.span>
                  </Link>
                </button> */}
                <button className="border-2 text-xl border-white py-[0.5rem] px-[1.5rem] max-sm:py-1 rounded-[.2rem] max-sm:rounded-3xl max-sm:text-[12px]">
                  <Link to={"/contact"} className="flex items-center gap-2">
                    Contact Us
                    <motion.span
                      animate={{ x: [20, 5, 20], y: 0 }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "reverse",
                      }}
                      className="inline-block font-light text-white"
                    >
                      <FaArrowRightLong />
                    </motion.span>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homebanner;
