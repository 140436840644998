import React, { useEffect } from "react";
import Banner from "../Components/Banner";
import Whyus from "../Components/Whyus";
import Visionbox from "../Components/Visionbox";
import Promotor from "../Components/Promotor";
import Global from "../Components/Global";
import Timeline from "../Components/Timeline";
import Slider from "../Components/Slider";
import Mobiletimeline from "../Components/Mobiletimeline";
import Mapcontact from "../Components/Mapcontact";
import Value from "../Components/Value";
import { useLocation } from "react-router-dom/dist";
import Main from "../Main";

const About = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Main>
      <div className="space-y-32  max-xl:space-y-24 max-lg:space-y-20 max-sm:space-y-10">
        <Banner heading={"About us"} />
        <Whyus
          img={"/images/why-banner-1.jpg"}
          topic={"About Us"}
          para={
            "We believe financial legacies built on diversified, sustainable assets stand the test of time. We are a family office dedicated to managing our family's wealth across a spectrum of traditional and alternative investments, from fixed income and private equity to venture capital and direct equity. Our core philosophy rests on disciplined diversification and a relentless pursuit of risk-adjusted returns. We leverage deep industry expertise, a global perspective, and an unwavering commitment to sustainability to navigate market complexities and uncover opportunities that deliver consistent, long-term value for our family."
          }
        />
        {
          <div className="block max-md:hidden">
            <Visionbox />
          </div>
        }
        {
          <div className="hidden max-md:block">
            <Slider />
          </div>
        }

        <Value />
        <Promotor />
        <Global />
        <div className="block max-xl:hidden">
          <Timeline />
        </div>

        <div className="hidden max-xl:block">
          <Mobiletimeline />
        </div>
        <Mapcontact />
      </div>
    </Main>
  );
};

export default About;
