import React, { useEffect, useState } from "react";
import Banner from "../Components/Banner";
import Whyus from "../Components/Whyus";
import { GoDotFill } from "react-icons/go";
import { useLocation } from "react-router-dom";
import Main from "../Main";

const Shubhalakshmipolyesters = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [Read, setRead] = useState(false);

  function show() {
    setRead(!Read);
  }
  return (
    <Main>

    <div className="space-y-52  max-xl:space-y-24 max-lg:space-y-20 max-sm:space-y-10">
      <Banner heading={"Shubhalakshmi Polyesters Ltd"} />
      <Whyus
        img={"/images/logos/shubhalakshmi-logo-banner.png"}
        heading={"ShubhaLakshmi Polyesters Limited"}
        topic={"Our Business"}
        para={
          "ShubhaLakshmi Polyesters Limited, established in 1985, was a pioneer in the Indian polyester industry. We built a legacy of excellence, contributing 7% of the nation's total polyester production at our peak. In 2022, as the landscape evolved, we strategically divested our polyester operations to Reliance Industries. This bold move marked the beginning of a new chapter, one where we embrace diversification and venture into exciting new territories."
        }
      />
      <div className=" flex justify-center   max-sm:px-3 my-24">
        <div className="w-[90%] max-sm:w-full  space-y-3">
          <ul className="flex items-start gap-4">
            <GoDotFill className="text-2xl max-xl:text-xl max-md:text-lg" />{" "}
            <li className=" para text-black font-jost font-semibold ">
              Deal with Reliance Industries Limited
            </li>
          </ul>

          <div className="flex max-lg:flex-col items-center gap-5">
            <div className="text-left w-[80%] max-xl:w-full space-y-3 ">
              <p className="text-[40px] max-xl:text-[25px]  font-bold leading-tight font-jost text-left max-sm:text-lg text-[#093E86] ">
                Reliance Industries acquires Shubhalakshmi Polyesters for Rs
                1,592 crore to boost its polyester business
              </p>
              <p className="  para">
                Reliance Industries Ltd has acquired polyester chips and yarn
                manufacturer Shubhalakshmi Polyesters Ltd for Rs 1,592 crore,
                billionaire. "Reliance Petroleum Retail Limited (under name
                change to ‘Reliance Polyester Limited’), a wholly owned
                subsidiary of the Company, executed definitive documents to
                acquire polyester business of Shubhalakshmi Polyesters Limited
                (SPL) and Shubhlaxmi Polytex Limited (SPTex) for cash
                consideration of Rs 1,522 crore and Rs 70 crore respectively,
                aggregating to Rs 1,592 crore by way of slump sale on a going
                concern basis," RIL said in the statement.
              </p>
              <p className=" para">
                Shubhalakshmi Polyesters has a continuous polymerisation
                capacity of 2,52,000 MT/Annum and manufactures polyester fibre,
                yarns and textile grade chips through direct polymerisation
                route as well as extruder spinning with value addition through
                texturising. It has two manufacturing facilities situated at
                Dahej (Gujarat) and Silvassa (Dadra and Nagar Haveli). SPTex has
                a texturised yarn manufacturing facility at Dahej.
              </p>
            </div>

            <div className="m-auto max-md:p-2">
              <img className="rounded   " src="/images/deal3.jpg" alt="" />
            </div>
          </div>

          {/* <div className="flex flex-col justify-center items-center "> */}
          <div className=" flex flex-col items-start  max-lg:hidden">
            {Read ? (
              <div className="m-auto space-y-3 ">
                <p className=" text-left ">
                  <span className="para">
                    The acquisitions are part of the Company’s strategy to
                    expand its downstream polyester business.
                  </span>
                </p>
                <p className=" text-left ">
                  <span className="para">
                    Reliance Industries said the above transactions do not fall
                    within the related party transactions and none of the
                    Company’s promoter / promoter group / group companies have
                    any interest in the above entities. The turnover of SPL for
                    FY 2019, FY 2020 and FY 2021 was Rs 2,702.50 crore, Rs
                    2,249.08 crore and Rs 1,768.39 crore respectively and
                    turnover of SPTex for FY 2019, FY 2020 and FY 2021 was Rs
                    337.02 crore, Rs 338.00 crore and Rs 267.40 crore
                    respectively.
                  </span>
                </p>
              </div>
            ) : (
              ""
            )}
            <button
              className="border-[1px] border-[#093E86] p-2 max-sm:p-1 rounded my-5 w-fit"
              onClick={show}
            >
              {!Read ? "  Read More" : "Close"}
            </button>
          </div>

          {/* </div> */}
        </div>
      </div>
    </div>
          </Main>
  );
};

export default Shubhalakshmipolyesters;
