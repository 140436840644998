import React from "react";
import Banner from "../Components/Banner";
import BlogContainer from "../Components/Blog/BlogContainer";
import { useLocation } from "react-router-dom/dist";
import { useEffect } from "react";
import Main from "../Main";

const Blog = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Main>
      <section className="space-y-32  max-xl:space-y-24 max-lg:space-y-20 max-sm:space-y-10">
        <Banner heading={"Blog"} />

        <BlogContainer />
      </section>
    </Main>
  );
};

export default Blog;
