import React, { useEffect, useState } from "react";
import Blogtable from "./Component/Blogtable";
import AdminMain from "./AdminMain";
import Heading from "./Component/Heading";
import { IoIosRefresh } from "react-icons/io";
import AdminBlogForm from "./Component/AdminBlogForm";
import { useDispatch, useSelector } from "react-redux";
import EditBlog from "./Component/EditBlog";
import axios from "axios";
import { addBlogData } from "../redux/AdminBlogSlice";
import { ToastContainer } from "react-toastify";

const AdminBlog = () => {
  const [loading, setLoading] = useState(false);
  const [IsFormOpen, setIsFormOpen] = useState(false);
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.AdminBlog.IsOpen);
  const isEdit = useSelector((state) => state.AdminBlog.IsEdit);
  const [blogData, setblogData] = useState([]);
  const [search, setSearch] = useState("");
  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/blog/getBlogData`
      );
      if (data.message) {
        setLoading(false);
      }
      setblogData(data.blogdata);
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleRefresh = () => {
    setLoading(!loading);
    getData();
  };

  return (
    <AdminMain>
      <div className="p-5 ">
        {isOpen ? <AdminBlogForm getData={getData} /> : ""}
        {isEdit ? <EditBlog getData={getData} /> : ""}
        <Heading
          heading={"Blog Add"}
          setIsFormOpen={setIsFormOpen}
          isOpen={isOpen}
          addData={addBlogData}
          add={true}
        />

        <div className="p-2 border-[1px] rounded-b-lg ">
          <div className="flex gap-3 border-b-[1px] pb-2 border-gray-100 mt-2 px-5 ">
            <div
              className="bg-[#06b4d2] w-fit p-2 rounded-lg border-2 "
              onClick={handleRefresh}
            >
              <IoIosRefresh
                className={`text-white text-2xl ${
                  loading ? "animate-spin " : ""
                } `}
              />
            </div>
            <div>
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search..."
                value={search}
                className="pr-16 placeholder:text-gray-400 placeholder:text-xl outline-none placeholder:font-light border-[1px] p-2 rounded-lg"
                onChange={(e) => {
                  const searchnew = e.target.value;
                  setSearch(searchnew);
                }}
              />
            </div>
          </div>
          <Blogtable search={search} blogData={blogData} getData={getData} />
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminBlog;
