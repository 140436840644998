import React, { useEffect } from "react";
import Banner from "../Components/Banner";
import Whyus from "../Components/Whyus";
import { useLocation } from "react-router-dom";
import Main from "../Main";

const Sunkon = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Main>

    <div className="space-y-32  max-xl:space-y-24 max-lg:space-y-20 max-sm:space-y-10">
      <Banner heading={"Sunkon Energy Pvt Ltd"} />
      <Whyus
        img={"/images/logos/sunkon-logo.png"}
        topic={"Our Business"}
        heading={"Sunkon Energy Pvt Ltd "}
        para={
          "SEPL, a joint venture company, was incorporated in year 2008 and is engaged in the business of renewable energy i.e. Electricity generation from Solar Power.  The company owns a 10 MW solar power plant at Amreli district in the state of Gujarat.The Company has entered long term power supply contract with Gujarat Electricity Development Authority (GEDA) for supply of power, which is an assured stream of revenue for the company."
        }
        />
    </div>
        </Main>
  );
};

export default Sunkon;
