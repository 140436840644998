import React from "react";
import { format } from "date-fns";
import axios from "axios";
const JobApplicationTable = ({ search, JobData }) => {
  const openFileInNewTab = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };
  const handleDownload = async (fileName) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/download/${fileName}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <div className="my-5">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border">No</th>
            <th className="py-2 px-4 border">Name</th>
            <th className="py-2 px-4 border">Mobile</th>
            <th className="py-2 px-4 border">Email</th>
            <th className="py-2 px-4 border">Attachment</th>
            <th className="py-2 px-4 border">Application Received on</th>
            <th className="py-2 px-4 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {JobData.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
          ).map((item, index) => (
            <tr key={item.id} className={index % 2 === 0 ? "bg-gray-100" : ""}>
              <td className="py-2 px-4 border">{index + 1}</td>
              <td className="py-2 px-4 border">{item.name}</td>
              <td className="py-2 px-4 border">{item.contactNumber}</td>
              <td className="py-2 px-4 border">{item.email}</td>
              <td
                className="py-2 px-4 border"
                onClick={() => {
                  handleDownload(item.file);
                }}
              >
                {item.file && item.file.includes(".")
                  ? item.file.substring(item.file.lastIndexOf(".") + 1)
                  : item.file}
              </td>
              <td className="py-2 px-4 border">
                {" "}
                {format(new Date(item.createdAt), " dd MMMM , yyyy ")}
              </td>
              <td className="py-2 px-4 border">
                {" "}
                {/* <button className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700">
                  Edit
                </button> */}
                <button className="ml-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-700">
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JobApplicationTable;
