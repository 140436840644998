import React from "react";

const AdminFooter = () => {
  return (
    <div className="w-full bg-slate-200 h-full text-2xl flex justify-center items-center  text-center p-5 ">
      All rights reserved @ 2023    Shubhalakshmi{" "}
    </div>
  );
};

export default AdminFooter;
