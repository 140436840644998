import React from "react";

const Visionbox = () => {
  return (
    <div className="flex justify-center items-center my-32 p-2 font-jost">
      {/* <div className=''>
                <img className='m-auto' src='/images/vision.png' alt='' />
                <div className='grid grid-cols-3 px-5'>
                    <div>
                        <p className='text-[#093E86] text-2xl font-semibold'>Vision</p>
                        <p> To Be The Most Preferred<br/> Choice For Our<br/> Customers And Employees.​</p>
                    </div>
                    <div>
                        <p className='text-[#093E86] text-2xl font-semibold'>Mission</p>
                        <p>To Be Among Top Three Leading<br/> Companies in <br/> the Business Segment In India. ​</p>
                    </div>

                    <div>
                        <p className='text-[#093E86] text-2xl font-semibold'>Goal</p>
                        <p>
                        We seek investments that align<br/> with our family's values and contribute<br/> to a better future for our planet. 
                        </p>
                    </div>
                </div>
            </div> */}
      <div className="flex flex-col justify-center items-center pt-1">
        <img className="" src="/images/section-1.png" alt="" />
        <p className="text-[#093E86] text-2xl font-semibold">Vision</p>
        <p className="text-[16px] max-w-[336px] p-1">
          To safeguard and grow our family wealth for generations to come,
          fostering stability, opportunity, and positive impact through
          responsible and diversified investments.
        </p>
      </div>
      <div className="pb-12">
        <img className="" src="/images/section-2.png" alt="" />
        <p className="text-[#093E86] text-2xl text-center font-semibold">
          Mission
        </p>
        <p className=" max-w-[330px] text-[16px] p-1">
          Cultivating a diverse portfolio of impactful startups, balancing risk
          with purpose, weaving financial security with<br/> positive legacy.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center">
        <img className="" src="/images/section-3.png" alt="" />
        <p className="text-[#093E86] text-2xl font-semibold">Goal</p>
        <p className=" max-w-[330px] text-[16px] p-1 font-jost">
          Balancing risk for consistent growth, investing responsibly, uniting
          family, leaving a positive legacy.
        </p>
      </div>

      {/* <div>
                 <div className=' flex justify-center items-center h-[335px]  w-[335px] rounded-full bg-gradient-to-b from-white from-50%  to-[#093E86] to-100% z-0 '>
                    <div className='bg-white w-fit p-10 rounded-full z-10 drop-shadow-2xl'>
                        <img src='/images/icons/analysis.png' alt='' />
                    </div>
                </div>
                <div>
                    <p className='text-[#093E86] font-semibold text-2xl'>Vison</p>
                </div>
            </div>
            <div className=' flex justify-center items-center h-[335px]  w-[335px] rounded-full bg-gradient-to-b from-[#093E86] from-0% to-[#FFFFFF] to-50% '>
                <div className='bg-white w-fit p-10 rounded-full drop-shadow-2xl'>
                    <img src='/images/icons/product.png' alt='' />
                </div>
            </div>
            <div className=' flex justify-center items-center h-[335px]  w-[335px] rounded-full bg-gradient-to-b from-white from-50%  to-[#093E86] to-100% '>
                <div className='bg-white w-fit p-10 rounded-full drop-shadow-2xl'>
                    <img src='/images/icons/target.png' alt='' />
                </div>
            </div> */}
    </div>
  );
};

export default Visionbox;
