import React from "react";
import "./DropDown.css";
import { Link } from "react-router-dom";

const Dropdown = () => {
  return (
    <section>
      <div class=" group inline-block">
        <button>
          <span>
            <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </span>
        </button>
        <ul
          class="bg-white flex flex-col text-black border rounded-sm transform scale-0 group-hover:scale-100 absolute right-0
                   transition duration-150 ease-in-out origin-top z-50 text-xs"
        >
          <Link to="/home" class="rounded-sm px-3 py-1 hover:bg-gray-100">
            Home
          </Link>
          <Link to="/about" class="rounded-sm px-3 py-1 hover:bg-gray-100">
            About Us
          </Link>
          <li class="rounded-sm relative px-3 py-1 hover:bg-gray-100">
            <button class="w-full text-left flex items-center outline-none focus:outline-none">
              <span className="pr-1 flex-1">Our Bussiness</span>
              <span class="mr-auto">
                <svg
                  class="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </span>
            </button>
            <ul className="bg-white border rounded-sm absolute left-0 transition duration-150 ease-in-out origin-top-right">
              <Link
                to={"/Shubhalakshmi_polyesters"}
                className="block px-4 py-2 text-sm  rounded-lg hover:bg-gray-50 hover:text-gray-700"
              >
                Shubhalakshmi Polyesters Ltd
              </Link>
              <Link
                to={"/sunkon"}
                className="block px-4 py-2 text-sm  rounded-lg hover:bg-gray-50 hover:text-gray-700"
              >
                Sunkon Energy Pvt Ltd
              </Link>
              <Link
                to={"/investment"}
                className="block px-4 py-2 text-sm  rounded-lg hover:bg-gray-50 hover:text-gray-700"
              >
                Invesments
              </Link>
            </ul>
          </li>
          {/* <Link to="/career" className="rounded-sm px-3 py-1 hover:bg-gray-100">
            career
          </Link> */}
          <Link
            to="/contact"
            className="rounded-sm px-3 py-1 hover:bg-gray-100"
          >
            Contact
          </Link>
        </ul>
      </div>
    </section>
  );
};

export default Dropdown;
