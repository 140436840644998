import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { MdOutlineDone } from "react-icons/md";

const Rightimageconten = () => {
  const [Read, setRead] = useState(false);

  function show() {
    setRead(!Read);
  }

  return (
    <section className="">
      <div className="grid grid-cols-3 gap-4 my-5   m-auto w-[90%]   max-md:w-full px-2 items-start   max-lg:flex max-lg:flex-col max-sm:px-3">
        <div className="text-left col-span-2  w-fit space-y-4 max-sm:space-y-3">
          <ul className="flex items-center gap-4 max-sm:gap-3">
            {/* <GoDotFill className="text-2xl max-xl:text-xl max-md:text-lg" />{" "} */}
            <li className=" para text-black font-jost font-semibold text-2xl hover:border-b-2 border-[#093E86]">
              Our ideology
            </li>
          </ul>
          <p className="text-[40px] max-xl:text-[25px] font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] ">
            What we are looking for as a Family Office? 
          </p>

          <p className="text-[#334455] para font-semibold   max-w-[900px] ">
            At the core of our investment philosophy lies diversification. We
            spread risk and opportunity across a variety of asset classes and
            geographies, including: 
          </p>
          <div className="">
            <ul className="  space-y-2">
              <li className="flex justify-start items-center gap-3 ">
                <div>
                  <MdOutlineDone className="text-[#093E86] font-bold text-3xl max-sm:text-xl" />
                </div>{" "}
                <span className="para">
                  {" "}
                  <span className="font-semibold">Fixed income</span>: Securing reliable income streams through bonds
                  and other instruments, navigating market fluctuations for
                  long-term stability.
                </span>
              </li>
              <li className="flex justify-start items-center gap-3  max-sm:border-black  ">
                <div>
                  <MdOutlineDone className="text-[#093E86] font-bold text-3xl max-sm:text-xl" />
                </div>{" "}
                <span className="para">
                <span className="font-semibold"> Public and private equities</span>: Capitalizing on growth potential
                  in established and emerging companies, from blue-chip giants
                  to disruptive startups.
                </span>
              </li>
              <li className="flex justify-start items-center gap-3  max-sm:border-black ">
                <div>
                  <MdOutlineDone className="text-[#093E86] font-bold text-3xl max-sm:text-xl" />
                </div>{" "}
                <span className="para">
                <span className="font-semibold"> Venture capital</span>: Investing in innovative ventures with
                  high-disruption potential, gaining access to the pioneers of
                  tomorrow's industries.
                </span>
              </li>
              <li className="flex justify-start items-center gap-3 ">
                <div>
                  <MdOutlineDone className="text-[#093E86] font-bold text-3xl max-sm:text-xl" />
                </div>{" "}
                <span className="para">
                <span className="font-semibold"> Direct stock selection</span>: Leveraging our expertise to identify
                  undervalued opportunities and capitalize on market
                  inefficiencies
                </span>
              </li>
              <li className="flex justify-start items-center gap-3 ">
                <div>
                  <MdOutlineDone className="text-[#093E86] font-bold text-3xl max-sm:text-xl" />
                </div>{" "}
                <span className="para">
                <span className="font-semibold"> PMS management</span>: Partnering with top-performing portfolio
                  managers for customized investment solutions and active
                  management expertise.
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className=" hidden max-lg:flex flex-col justify-center items-center  ">
          <div className="w-[90%] flex flex-col items-start ">
            {Read ? (
              <div className="space-y-3 ">
                <p className="para text-left">
                  We believe in a risk-adjusted approach, prioritizing capital
                  preservation while seeking attractive returns. Our rigorous
                  investment process balances potential rewards with careful
                  risk management, ensuring our wealth is in safe hands. 
                </p>
                <p className=" para  text-left">
                    Beyond mere financial returns, we prioritize ethical and
                    sustainable practices. We seek investments that align with
                    our family's values and contribute to a better future for
                    our planet. Join us on a journey of financial security and
                    lasting legacy. This website serves as a window into our
                    investment philosophy and commitment to stewarding our
                    family's wealth for generations to come. 
                </p>
              </div>
            ) : (
              ""
            )}
            <button
              className="border-[1px] border-[#093E86] px-3 max-sm:p-1 rounded my-5 para font-medium "
              onClick={show}
            >
              {!Read ? " More" : "Close"}
            </button>
          </div>
        </div>

        <div className="">
          <img className="rounded h-[60vh] " src="/images/Frame.png" alt="" />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center  max-lg:hidden">
        <div className="w-[90%] flex flex-col items-start ">
          {Read ? (
            <div className="m-auto space-y-3 ">
              <p className=" text-left ">
                <span className="para">
                  We believe in a risk-adjusted approach, prioritizing capital
                  preservation while seeking attractive returns. Our rigorous
                  investment process balances potential rewards with careful
                  risk management, ensuring our wealth is in safe hands. 
                </span>
              </p>
              <p className=" text-left ">
                <span className="para">
                  Beyond mere financial returns, we prioritize ethical and
                  sustainable practices. We seek investments that align with our
                  family's values and contribute to a better future for our
                  planet. Join us on a journey of financial security and lasting
                  legacy. This website serves as a window into our investment
                  philosophy and commitment to stewarding our family's wealth
                  for generations to come. 
                </span>
              </p>
            </div>
          ) : (
            ""
          )}
          <button
            className="border-2 text-[16px] border-[#093E86] py-[0.5rem] px-[1.5rem] max-sm:py-1 rounded-[.2rem] max-sm:rounded-3xl max-sm:text-[12px]"
            onClick={show}
          >
            {!Read ? "  Read More" : "Close"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Rightimageconten;
