import React from "react";
import AdminMain from "./AdminMain";

const AdminDashboard = () => {

  return (
    <AdminMain>
      <div className="h-full bg-white w-full text-black text-2xl">
        DashBoard
      </div>
    </AdminMain>
  );
};

export default AdminDashboard;
