import React from "react";
import { FaBars } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const AdminNavbar = ({ sideMenuIsExpand, setSideMenuIsExpand }) => {
  const navigate = useNavigate();
  const logoutAccount = () => {
    localStorage.removeItem("authadmin");
    navigate("/admin/admin-shubhalakshmi-login");
  };
  return (
    <div className=" p-3 bg-slate-200 flex flex-col justify-center text-[#093E86] mb-20">
      <div className="flex items-center justify-between px-3">
        <div className="flex gap-5 items-center">
          <div>
            <FaBars
              className="text-2xl font-bold"
              onClick={() => {
                setSideMenuIsExpand(!sideMenuIsExpand);
              }}
            />
          </div>
          <div className="flex text-2xl font-normal gap-1">
            Welcome,
            <h1 className=" text-2xl font-normal"> Admin</h1>
          </div>
        </div>
        <div className="text-2xl font-bold"> Shubhalakshmi </div>
        <div className="flex-col items-center cursor-pointer">
          <div className="flex justify-center">
            <LuUser2
              className="text-xl font-bold text-center "
              onClick={logoutAccount}
            />
          </div>{" "}
          <div className="text-[10px] ">Logout</div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
