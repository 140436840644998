import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Keyboard, EffectCreative } from "swiper/modules";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
const Data = [
  {
    title: "Vison",
    para: `  To safeguard and grow our family wealth for generations to come,
    fostering stability, opportunity, and positive impact through
    responsible and diversified investments..​`,
    src: "/images/section-1.png",
  },
  {
    title: "Mission",
    para: " Cultivating a diverse portfolio of impactful startups, balancing risk  with purpose, weaving financial security with positive legacy.. ​",
    src: "/images/section-2.png",
  },
  {
    title: "Goal",
    para: " Balancing risk for consistent growth, investing responsibly, uniting family, leaving a positive legacy..​",
    src: "/images/section-3.png",
  },
];
const Slider = () => {
  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="button-next bg-slate-300 p-1 rounded-full">
          <BsArrowLeft className="text-black font-bold text-xl" />
        </div>
        <Swiper
          modules={[Keyboard, Navigation, EffectCreative]}
          keyboard={{
            enabled: true,
            onlyInViewport: false, // Set to true if you want to control Swiper only when it's in the viewport
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          loop={true}
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          className="mySwiper"
        >
          {Data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="m-auto flex flex-col justify-center items-center bg-white p-2">
                  <img className="" src={item.src} alt="" />
                  <div className="m-auto">
                    <p className="text-[#093E86] text-2xl font-semibold">
                      {item.title}
                    </p>
                    <p>{item.para}​</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="button-prev bg-slate-300 p-1 rounded-full cursor-pointer">
          <BsArrowRight className="text-black font-bold text-xl" />
        </div>
      </div>
    </div>
  );
};

export default Slider;
