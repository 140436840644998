import { createSlice } from "@reduxjs/toolkit";

// Initialize auth state from local storage
const initialState = {
  auth: JSON.parse(localStorage.getItem("authadmin")) || {
    user: null,
    token: "",
  },
};

const AuthSlice = createSlice({
  name: "authAdmin",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const { user, token } = action.payload;
      state.auth.user = user;
      state.auth.token = token;
      // Store auth data securely in local storage
      localStorage.setItem("authadmin", JSON.stringify({ user, token }));
    },
    clearAuth: (state) => {
      state.auth = { user: null, token: "" };
      // Remove auth data from local storage when user logs out
      localStorage.removeItem("authadmin");
    },
  },
});

export const { setAuth, clearAuth } = AuthSlice.actions;

export default AuthSlice.reducer;
