import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SetID } from "../../redux/CareerSlice";

const ProfileTemplate = ({ singleItem }) => {
  console.log(singleItem);
  const dispatch = useDispatch();
  return (
    <div>
      <>
        <div className="border-[1px] rounded-[12px] border-black relative p-7 max-sm:p-4 flex flex-col justify-center items-start box-Shadow space-y-2 h-[183px] max-lg:h-[171px] max-md::h-[168px] max-xl:w-[315px] max-lg:w-full ">
          <h1 className=" text-[25px] text-left max-lg:text-[20px] max-sm:text-[15px] leading-[100%]    tracking-[2.5px]  max-lg:tracking-[2px] max-sm:tracking-[1.5px] font-medium  text-[#black]">
            {" "}
            {singleItem.jobTitle}
          </h1>
          <p className="text-[16px] max-lg:text-[15px] max-md::text-[12px] leading-normal max-lg:leading-tight tracking-[1px] max-lg:tracking-[0.75px] font-normal  text-black text-left ">
            {singleItem.jobDescription.slice()}
          </p>

          <div className="absolute right-0 bottom-0 ">
            <Link
              to="/careerProfile"
              onClick={() => {
                dispatch(SetID(singleItem._id));
              }}
              className=" w-full border-black flex  border-l-[1px] border-t-[1px] h-10 px-3 rounded-br-[12px] hover:bg-[#093E86] transition-all hover:text-white duration-200 ease-in-out "
            >
              Apply
            </Link>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProfileTemplate;
