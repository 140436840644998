


import React from "react";
import { GoDotFill } from "react-icons/go";
import Banner from "./Banner";

const Mapcontact = () => {
  return (
    <div>
      <div className="">
        <div className="px-20 max-xl:px-15 max-lg:px-10 max-sm:px-0 relative">
          <img
            src="/images/World_map.jpg"
            alt="#img"
            className=" h-[750px] max-xl:h-[450px] max-sm:h-[300px] max-small-sm:h-[250px]   w-full "
          />

          <div className=" absolute top-0 flex flex-col justify-between h-full ">
            <div className="mt-10 max-md:mt-5 pl-10">
      
              <p className="text-[50px] text-left max-xl:text-[30px] font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] ">
                Contact Us
              </p>
            </div>

            <div className="bg-[#093E86]  p-5 text-[20px] max-lg:text-[15px]  text-white  mb-6 rounded-br-[30px] rounded-t-[30px] max-sm:p-2 max-lg:hidden">
              <p className="flex gap-3 items-center y-2">
                <span>
                  <img
                    className="h-5 "
                    src="/images/icons/phone-call.png"
                    alt=""
                  />
                </span>{" "}
                +91 8799451956
              </p>
              <p className="flex items-center gap-3 py-2">
                <img className="h-5" src="/images/icons/email.png" alt="" />
                <span>investment@shubhalakshmi.com</span>{" "}
              </p>
              <p className="flex gap-3 py-2  ">
                <span className="flex  gap-2 justify-start items-start text-left">
                  <img className="h-5 mt-1" src="/images/icons/gps.png" alt="" />
                  1010 Rajhans Montessa, Dumas Rd,
                  <br /> beside Le Meridien Hotel, near Airport,
                  <br />
                  Surat, Gujarat 395007
                </span>{" "}
              </p>
              <p className="flex gap-3 py-2">
                <span className="flex  gap-2 text-left">
                  <img className="h-5 mt-1 " src="/images/icons/gps.png" alt="" />
                  Shubhalakshmi Polyesters Ltd. 1101 <br />
                Lodha Supremus, Tulsi Pipe Road,
                  <br /> Lower Parel, Mumbai 400013
                </span>{" "}
              </p>
              {/* <p className="flex gap-3 py-2">
                <span className="flex  gap-2 text-left">
                  <img className="h-5 " src="/images/icons/gps.png" alt="" />
                  Shubhalakshmi Polyesters Ltd. 1101
                  <br /> Lodha Supremus, Tulsi Pipe Road,
                  <br />
                  Lower Parel, Mumbai 400013
                </span>{" "}
              </p> */}
            </div>
          </div>
        </div>

        <div className="bg-[#093E86]  p-5 mx-10 max-sm:mx-3 w-1/2 max-md:w-fit text-white text-[15px] mb-6 rounded-br-2xl rounded-t-2xl max-sm:p-2 hidden max-lg:block">
          <p className="flex gap-3 py-2">
            <span>
              <img
                className="h-5 w-5"
                src="/images/icons/phone-call.png"
                alt=""
              />
            </span>{" "}
            +91 8799451956
          </p>
          <p className="flex gap-3 py-2 ">
            <img className="h-5 w-5" src="/images/icons/email.png" alt="" />
            <span>investment@shubhalakshmi.com</span>{" "}
          </p>
          <p className="flex  gap-3 py-2">
            <img className="h-5 w-5 mt-1" src="/images/icons/gps.png" alt="" />
            <span className="text-left">
              1010 Rajhans Montessa, Dumas Rd, beside Le Meridien Hotel, near
              Airport, Surat, Gujarat 395007
            </span>
          </p>
          <p className="flex  gap-3 py-2">
            <img className="h-5 w-5 mt-1" src="/images/icons/gps.png" alt="" />
            <span className="text-left">
            Shubhalakshmi Polyesters Ltd. 1101
                Lodha Supremus, Tulsi Pipe Road,
                   Lower Parel, Mumbai 400013
            </span>
          </p>
         
        </div>
      </div>
    </div>
  );
};

export default Mapcontact;
