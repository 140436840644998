import React, { Children, useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import AdminNavbar from "./AdminNavbar";
import AdminFooter from "./AdminFooter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setAuth } from "../redux/AuthSlice";

const AdminMain = ({ children }) => {
  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(true);
  // const dispatch = useDispatch();
  // const data = JSON.parse(localStorage.getItem("authadmin"));
  // console.log(data);
  // useEffect(() => {
  //   dispatch(
  //     setAuth({
  //       user: data.user,
  //       token: data.token,
  //     })
  //   );
  // });
  return (
    <div className="flex h-screen">
      <ToastContainer />
      <div
        className={` ${
          sideMenuIsExpand ? "w-1/5" : "w-1/12"
        } transition-all duration-200 ease-in-out relative min-h-screen md:flex`}
      >
        <AdminSidebar sideMenuIsExpand={sideMenuIsExpand} />
      </div>
      <div className="w-full flex flex-col">
        <header className="">
          <AdminNavbar
            sideMenuIsExpand={sideMenuIsExpand}
            setSideMenuIsExpand={setSideMenuIsExpand}
          />
        </header>
        <main className="h-full overflow-y-auto ">
          {/* <ToastContainer /> */}
          {children}
        </main>
        <footer className=" flex justify-center items-center">
          <AdminFooter />
        </footer>
      </div>
    </div>
  );
};

export default AdminMain;
