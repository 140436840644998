import React from "react";
import { GoDotFill } from "react-icons/go";
import { MdOutlineDone } from "react-icons/md";

const Value = () => {
  return (
    <div className="flex justify-center my-16 ">
      <div className="flex max-md:flex-col gap-10 max-md:gap-6 max-sm:text-left w-[90%]">
        <div className="">
          <ul className="flex items-center gap-4 max-sm:gap-3 my-3">
           {" "}
            <li className=" para text-black font-jost font-semibold text-2xl ">
              Our Values
            </li>
          </ul>

          {/* <ul className=" space-y-2 max-sm:space-y-1 ">
            <li className="flex items-center gap-2">
              <div>
                <MdOutlineDone className="text-[#093E86] font-bold  text-[25px] max-lg:text-[20px]   max-sm:text-[16px]  " />
              </div>{" "}
              <p className="para">Customer Is First </p>
            </li>

            <li className="flex items-center gap-2">
              <MdOutlineDone className="text-[#093E86] font-bold  text-[25px] max-lg:text-[20px]   max-sm:text-[16px]  " />{" "}
              <p className="para"> Passion For Excellence In Quality </p>
            </li>
            <li className="flex items-center gap-2">
              <div>
                <MdOutlineDone className="text-[#093E86] font-bold  text-[25px] max-lg:text-[20px]   max-sm:text-[16px]  " />
              </div>{" "}
              <p className="para">
                {" "}
                Creativity And Entrepreneurship – Out Of Box Thinking 
              </p>
            </li>
            <li className="flex items-center gap-2">
              <div>
                <MdOutlineDone className="text-[#093E86] font-bold  text-[25px] max-lg:text-[20px]   max-sm:text-[16px]  " />
              </div>{" "}
              <p className="para"> Put “Team” Before Self And Work As A Team</p>
            </li>
            <li className="flex items-center gap-2">
              <div>
                <MdOutlineDone className="text-[#093E86] font-bold  text-[25px] max-lg:text-[20px]   max-sm:text-[16px]  " />
              </div>{" "}
              <p className="para">
                {" "}
                Respect For Individuals And Ethics – Treat All With Modesty,
                Respect And Dignity
              </p>
            </li>
          </ul> */}
          <p className=" para font-normal   max-w-[900px]">
          Our family office goes beyond managing wealth, we cultivate enduring legacies. We combine financial expertise with legal, tax, and philanthropic guidance, tailoring solutions to your unique family's goals and values. We ensure seamless intergenerational wealth transfer and empower your family with concierge services, lifestyle resources, and philanthropic guidance. Together, we weave a tapestry of financial security, family harmony, and lasting impact. Choose us as partners to build a future where your legacy echoes through generations.
          </p>
        </div>
        <div className="max-md:m-auto max-md:p-2 m-auto w-fit">
          <img className="rounded " src="/images/value.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Value;
