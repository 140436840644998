import React from 'react'
import Main from '../Main'
import Banner from '../Components/Banner'
import { useLocation } from "react-router-dom/dist";
import { useEffect } from "react";
import Rightimageconten from '../Components/Rightimageconten'

function Futureprospects(props) {
    const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <Main>
            <Banner heading={"Future Prospects"} />
            <div className='flex py-10 max-md:flex-col'>
                <div className='m-auto text-left px-10 max-lg:px-5  max-md:py-10     '>
                    <div className='py-4 max-lg:py-2'>
                        <p className='text-[32px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] '>Expanding Our Horizons:</p>
                        <p className='w-full  font-jost font-normal text-justify text-[#383838]  para_Home '>We are constantly looking for new ways to grow and serve our community. As part of this ongoing commitment, we are pursuing several exciting future interests, including:
                        </p>
                    </div>
                    <div className='py-4 max-lg:py-2'>
                        <p className='text-[32px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] '>Leasing and Renting Properties:</p>
                        <p className='w-full  font-jost font-normal text-justify text-[#383838]  para_Home '>We plan to leverage our expertise in property management to offer leasing and renting options for both residential and commercial spaces. This will allow us to create new revenue streams while providing valuable services to the community.
                        </p>
                    </div>
                </div>
                <div className='px-10 max-lg:px-5'>
                    <img src='./images/Future_Prospects-3.png' alt='' />
                </div>
            </div>
            <div className='flex py-10 max-md:flex-col'>
            <div className='px-10'>
                    <img src='./images/Future_Prospects-2.png' alt='' />
                </div>
                <div className='flex text-left px-10 max-md:py-10 '>
                <div className=' m-auto'>
                        <p className='text-[32px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] '>Mining:</p>
                        <p className='w-full  font-jost font-normal text-justify text-[#383838]  para_Home '> We are exploring the possibility of entering the mining sector, with a focus on limestone, hydrated lime, quicklime, and coal. These resources have widespread industrial applications, and responsible mining practices can contribute to economic development.
                        </p>
                    </div>
                   
                </div>
                
            </div>
            <div className='flex py-10 max-md:flex-col'>
                <div className='flex text-left px-10 max-md:py-10 '>
                    <div className=' m-auto'>
                        <p className='text-[32px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] '>Warehousing:</p>
                        <p className='w-full  font-jost font-normal text-justify text-[#383838]  para_Home '> Recognizing the growing demand for storage solutions, we are considering establishing warehousing facilities. This will cater to the needs of businesses and individuals alike, providing secure and convenient storage options.
                        </p>
                    </div>
                   
                </div>
                <div className='px-10'>
                    <img src='./images/Future_Prospects-1.png' alt='' />
                </div>
            </div>
            <div className='flex py-10'>
                <div className=' text-left px-10 '>
                    <div className=' m-auto py-4 '>
                        <p className='text-[32px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] '>Building a Sustainable Future:</p>
                        <p className='w-full  font-jost font-normal text-justify text-[#383838]  para_Home '> We are committed to pursuing these future interests in a responsible and sustainable manner. We will prioritize environmental considerations, ethical sourcing practices, and community engagement in all our endeavours.
                        </p>
                    </div>
                    <div className=' m-auto  py-4'>
                        <p className='text-[32px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] '>Stay Updated:</p>
                        <p className='w-full  font-jost font-normal text-justify text-[#383838]  para_Home '>We are excited about the possibilities these future interests hold and will keep you updated on our progress. Please revisit this page periodically for the latest developments.

                        </p>
                    </div>
                    <div className=' m-auto  py-2'>
                        <p className='text-[32px] max-xl:text-[30px]  font-bold leading-normal font-jost max-sm:text-lg text-[#093E86] '>We look forward to sharing our journey with you!</p>
                        
                    </div>
                   
                </div>
                
            </div>
            <div>

            </div>
        </Main>
    )
}

export default Futureprospects