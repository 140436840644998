import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const BlogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    SetID: (state, action) => {
      localStorage.setItem("blogID", JSON.stringify(action.payload));
    },
  },
});

export const { SetID } = BlogSlice.actions;

export default BlogSlice.reducer;
