import React, { useEffect } from "react";
import Main from "../Main";
import Banner from "../Components/Banner";
import { useLocation } from "react-router-dom";

const PrivacyPage = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(500, 0);
  }, [pathname]);
  return (
    <Main>
      <div className="">
        <Banner heading={"Privacy Policy"} />
        <div className="flex justify-center mt-20">
          <div className="w-[90%]">
            <div className="space-y-4">
              <h1 className="text-[50px] max-md:text-[30px] max-sm:text-[20px] text-[#093E86] font-bold leading-normal text-left w-[70%] max-sm:w-full  mt-5">
                Privacy policy
              </h1>
              <p className="para">
                At MOO, we value your privacy. So we're letting you know about
                some changes we're making to our Privacy Policy. They come into
                effect on 16th of May, 2018.
              </p>
              <p className="para">
                At MOO, we value your privacy. So we're letting you know about
                some changes we're making to our Privacy Policy. They come into
                effect on 16th of May, 2018.
              </p>
              <p className="para">
                At MOO, we value your privacy. So we're letting you know about
                some changes we're making to our Privacy Policy. They come into
                effect on 16th of May, 2018.
              </p>
              <p className="para">
                At MOO, we value your privacy. So we're letting you know about
                some changes we're making to our Privacy Policy. They come into
                effect on 16th of May, 2018.
              </p>
              <p className="para">
                At MOO, we value your privacy. So we're letting you know about
                some changes we're making to our Privacy Policy. They come into
                effect on 16th of May, 2018.
              </p>
            </div>{" "}
          </div>
        </div>
      </div>
    </Main>
  );
};

export default PrivacyPage;
