import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./AuthSlice";
import AdminCareerSlice from "./AdminCareerSlice";
import CareerSlice from "./CareerSlice";
import AdminBlogSlice from "./AdminBlogSlice";
import BlogSlice from "./BlogSlice";

const Store = configureStore({
  reducer: {
    authAdmin: AuthSlice,
    Admincareer: AdminCareerSlice,
    career: CareerSlice,
    AdminBlog: AdminBlogSlice,
    blog:BlogSlice
  },
});
export default Store;
