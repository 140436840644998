import React, { useEffect } from "react";
import Banner from "../Components/Banner";
import Mapcontact from "../Components/Mapcontact";
import Statupbox from "../Components/Statupbox";
import { useLocation } from "react-router-dom";
import Main from "../Main";
import Rightimageconten from "../Components/Rightimageconten";

const Investments = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Main>
      <div className="space-y-32  max-xl:space-y-24 max-lg:space-y-20 max-sm:space-y-10">
        <Banner heading={"Investments"} />
        <Rightimageconten />
        {/* <p className='w-full  font-jost  text-justify   para_Home py-10 px-10'>In the fertile ground of ideas, we sow the seeds of tomorrow. At Shubhalakshmi we're not just investors, we're cultivators. We seek ventures where audacity meets purpose, where passion fuels disruption, and where innovation blossoms into impact. We back visionary founders, nurturing their dreams with capital and guidance, because we believe in the power of human ingenuity to reshape the world.</p> */}
        {/* <Statupbox /> */}
        <Mapcontact />
      </div>
    </Main>
  );
};

export default Investments;
