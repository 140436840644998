import React, { useState } from "react";
import * as EmailJS from "emailjs-com";
import axios from "axios";
import { toast } from "react-toastify";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    contactNumber: "",
    message: "",
    additionalField: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate Name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email is required";
      isValid = false;
    }

    // Validate Subject
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
      isValid = false;
    }

    // Validate Contact Number
    const phoneRegex = /^[0-9]+$/;
    if (
      !formData.contactNumber.trim() ||
      !phoneRegex.test(formData.contactNumber)
    ) {
      newErrors.contactNumber = "Valid contact number is required";
      isValid = false;
    }

    // Validate Message
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    // Additional field validation (customize as needed)
    if (!formData.additionalField.trim()) {
      newErrors.additionalField = "This field is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      sendDatatServer();
      setloading(true);
    } else {
      alert("Form is invalid. Please fix the errors.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const sendDatatServer = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/auth/register`,
      formData
    );
    if (data.success && data.success) {
      setloading(false);
      setFormData({
        name: "",
        email: "",
        subject: "",
        contactNumber: "",
        message: "",
        additionalField: "",
      });
      alert("Form Submitted...");
    } else {
      setloading(false);
      alert(data.message);
    }
  };
  return (
    <section className="my-20">
      <div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center max-sm:p-3"
        >
          <h1 className="text-[50px] max-md:text-[30px] max-sm:text-[20px] text-[#093E86] font-bold leading-normal text-left w-[70%] max-sm:w-full  mt-5">
            Write us something
          </h1>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-6 gap-4 w-[70%] max-sm:w-full ">
            {/* Name Field */}
            <div className="w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Your Name
              </label>
              <br />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Your Name here"
                className="px-2 p-2 outline-none border-[1px] w-full placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.name && <div className="text-red-500 para">{errors.name}</div>}
            </div>

            {/* Email Field */}
            <div className="w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Your Email
              </label>
              <br />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Your Email here "
                className="px-2 p-2 border-[1px] outline-none w-full placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.email && (
                <div className="text-red-500 para">{errors.email}</div>
              )}
            </div>

            {/* Subject Field */}
            <div className="w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Your Subject
              </label>
              <br />
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder="Your Subject here "
                className="px-2 p-2 border-[1px]  outline-none w-full placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.subject && (
                <div className="text-red-500 para">{errors.subject}</div>
              )}
            </div>

            {/* Contact Number Field */}
            <div className="w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal mb-2"
              >
                Your Contact
              </label>
              <br />
              <input
                type="text"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
                placeholder="Your Phone here "
                className="px-2 p-2 border-[1px] outline-none border-[#DEE2E6] w-full placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.contactNumber && (
                <div className="text-red-500 para">{errors.contactNumber}</div>
              )}
            </div>

            {/* Message Field */}
            <div className="col-span-2 max-md:col-span-1 w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Message
              </label>
              <br />
              <textarea
                type="text"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Tell us a few words"
                className="px-2 p-2 border-[1px] w-full outline-none placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              ></textarea>
              {errors.message && (
                <div className="text-red-500 para">{errors.message}</div>
              )}
            </div>

            {/* Additional Field */}
            <div className="col-span-2 max-md:col-span-1  w-full text-left">
              <label
                htmlFor=""
                className="text-[#909090] text-[15px] font-semibold leading-normal "
              >
                Additional Field
              </label>
              <br />
              <input
                type="text"
                name="additionalField"
                value={formData.additionalField}
                onChange={handleChange}
                required
                placeholder=""
                className="px-2 p-2 border-[1px] w-full outline-none placeholder:text-[#D4D4D4] placeholder:text-[15px] placeholder:font-normal"
              />
              {errors.additionalField && (
                <div className="text-red-500 para">{errors.additionalField}</div>
              )}
            </div>

            {/* Submit Button */}
            <div className="flex justify-start">
              <button
                type="submit"
                className={`text-[20px] font-bold leading-normal text-white bg-[#093E86] py-2 px-3 tracking-[2px] relative rounded-md ${
                  loading ? " cursor-not-allowed" : ""
                }`}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex gap-2">
                    <div className="animate-spin rounded-full border-t-4 border-blue-500 border-t-blue-500 h-6 w-6 mx-auto"></div>
                    <span>loading</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
